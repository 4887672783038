import PageSection from './PageSection';

import { getString } from '@/content/i18n';
import { ModalId, ModalIdValue } from '@/constants/Modal';
import { showModal } from '@/actions/ModalActionCreators';
import AboutUs from '@/components/shared/home/AboutUs';
import ExternalLink from '@/components/shared/ExternalLink';
import Feature from '@/weblab/Feature';
import FeatureEnabled from '@/components/util/features/FeatureEnabled';
import FeatureGate from '@/components/util/features/FeatureGate';
import Link from '@/router/Link';
import S2Dispatcher from '@/utils/S2Dispatcher';
import SubscribeForm from '@/components/shared/home/SubscribeForm';

import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import type { Nullable, ReactNodeish } from '@/utils/types';

type Props = {
  className?: Nullable<string>;
  theme?: string;
};

export default class AppFooter extends React.PureComponent<Props> {
  static defaultProps = {
    theme: 'light',
  };

  static contextTypes = {
    dispatcher: PropTypes.instanceOf(S2Dispatcher).isRequired,
  };

  declare context: {
    dispatcher: S2Dispatcher;
  };

  showModal = (id: ModalIdValue): void => {
    this.context.dispatcher.dispatch(showModal({ id }));
  };

  render(): ReactNodeish {
    const { className, theme } = this.props;

    return (
      <footer className={classnames('footer', className)} role="contentinfo">
        <FeatureGate feature={Feature.HubspotNewsletterForm}>
          <FeatureEnabled>
            <SubscribeForm theme={theme} />
          </FeatureEnabled>
        </FeatureGate>
        <AboutUs />
        <PageSection className="footer__bottom-links__section">
          <div className="container flex-row-vcenter footer__bottom-links__container">
            <div>
              <div key="credits" className="footer__row">
                {getString(_ => _.appFooter.creditsPrologue)}
                <ExternalLink href="http://allenai.org" data-test-id="footer-ai2-link">
                  {getString(_ => _.appFooter.ai2)}
                </ExternalLink>
              </div>
              <div key="tos" className="footer__row">
                <span className="collab-attributions-link">
                  <ModalLink
                    onClick={this.showModal}
                    modalId={ModalId.COLLABORATORS}
                    testId="footer-collab-link">
                    <>{getString(_ => _.appFooter.collaboratorsAttributions)}</>
                  </ModalLink>
                </span>
                &bull;
                <ExternalLink
                  className="tos-link"
                  allowReferrer
                  href="https://allenai.org/terms.html"
                  data-test-id="footer-tos-link"
                  aria-label={getString(_ => _.appFooter.tos)}>
                  {getString(_ => _.appFooter.tos)}
                </ExternalLink>
                &bull;
                <ExternalLink
                  className="pp-link"
                  allowReferrer
                  href="https://allenai.org/privacy-policy.html"
                  data-test-id="footer-privacy-link"
                  aria-label={getString(_ => _.appFooter.privacy)}>
                  {getString(_ => _.appFooter.privacy)}
                </ExternalLink>
                &bull;
                <Link
                  className="api-license-link"
                  to="PRODUCT_API_LICENSE"
                  shouldOpenInNewTab={true}
                  data-test-id="footer-api-license-link"
                  aria-label={getString(_ => _.appFooter.apiLicense)}>
                  <>{getString(_ => _.appFooter.apiLicense)}</>
                </Link>
              </div>
            </div>

            <ExternalLink
              allowReferrer
              href="http://allenai.org"
              className="footer-ai2-logo flex-right"
              aria-label={getString(_ => _.appFooter.companyName)}>
              {getString(_ => _.appFooter.companyName)}
            </ExternalLink>
          </div>
        </PageSection>
      </footer>
    );
  }
}

type ModalLinkProps = React.PropsWithChildren<{
  modalId: ModalIdValue;
  onClick: (modalId: ModalIdValue) => void;
  testId?: string;
}>;

export const ModalLink = ({ modalId, onClick, children, testId }: ModalLinkProps): ReactNodeish => (
  <button className="link-button" data-test-id={testId} onClick={() => onClick(modalId)}>
    {children}
  </button>
);
