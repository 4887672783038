export type LanguageMap = typeof langMap;

const langMap = {
  general: {
    time: 'time',
    year: 'year',
    citationSingle: 'citation',
    citationPlural: 'citations',
    publicationSingle: 'publication',
    publicationPlural: 'publications',
    quantity: {
      lessThan: 'less than',
    },
    andX: 'and $1', // $1 string to follow 'and'
    XAndY: '$1 and $2', // $1, $2 strings to be joined by 'and'
    XAmpY: '$1 & $2', // $1, $2 strings to be joined by '&'
    appName: 'Semantic Scholar',
    fullPageTitle: '$1 | Semantic Scholar', // $1 title of page the user is on
    newTab: ' (opens in a new tab)',
    scrollToTop: 'Scroll to top of page',
  },

  pronouns: {
    HE_HIM: 'he/him',
    SHE_HER: 'she/her',
    THEY_THEM: 'they/them',
    NONE: 'no pronouns',
  },

  fieldOfStudy: {
    // NOTE: the key here is FieldOfStudy.id, the value MAY NOT be the name from the server
    'agricultural-and-food-sciences': 'Agricultural and Food Sciences',
    art: 'Art',
    biology: 'Biology',
    business: 'Business',
    'computer-science': 'Computer Science',
    chemistry: 'Chemistry',
    economics: 'Economics',
    education: 'Education',
    engineering: 'Engineering',
    'environmental-science': 'Environmental Science',
    geography: 'Geography',
    geology: 'Geology',
    history: 'History',
    law: 'Law',
    linguistics: 'Linguistics',
    'materials-science': 'Materials Science',
    mathematics: 'Mathematics',
    medicine: 'Medicine',
    philosophy: 'Philosophy',
    physics: 'Physics',
    'political-science': 'Political Science',
    psychology: 'Psychology',
    sociology: 'Sociology',
    all: 'All Fields',
    unsupported: 'Unsupported',
  },

  aboutUs: {
    about:
      'Semantic Scholar is a free, AI-powered research tool for scientific literature, based at Ai2.',
    label: 'What Is Semantic Scholar?',
    learnMore: 'Learn More',

    company: {
      label: 'About',
      about: 'About Us',
      team: 'Meet the Team',
      publishers: 'Publishers',
      blog: 'Blog',
      careers: 'Ai2 Careers',
    },

    product: {
      label: 'Product',
      productOverview: 'Product Overview',
      reader: 'Semantic Reader',
      scholarsHub: "Scholar's Hub",
      beta: 'Beta Program',
      releaseNotes: 'Release Notes',
    },

    api: {
      label: 'API',
      overview: 'API Overview',
      tutorials: 'API Tutorials',
      documentation: 'API Documentation',
      gallery: 'API Gallery',
    },

    research: {
      label: 'Research',
      publications: 'Publications',
      team: 'Researchers',
      researchCareers: 'Research Careers',
      prototypes: 'Prototypes',
      resources: 'Resources',
    },

    help: {
      label: 'Help',
      librarians: 'Librarians',
      tutorials: 'Tutorials',
    },
  },

  ariaLabelledByIds: {
    paperTitleId: 'paper-$1',
  },

  appHeader: {
    logoAriaLabel: 'Homepage',
    logoText: 'Semantic Scholar',
    citeDropdownAriaLabel: 'Citation type filter',
    fosDropdownAriaLabel: 'Field of study filter',
    searchInputAriaLabel: 'Search text',
    searchSubmitAriaLabel: 'Submit',
    searchButtonLabel: 'Search',
    faqAriaLabel: 'Frequently asked questions about Semantic Scholar',
    aboutAriaLabel: 'About Semantic Scholar',
    feedbackModalAriaLabel: 'Open Contact form',
    FAQButtonLabel: 'FAQ',
    aboutButtonLabel: 'About',
    contactUsButtonLabel: 'Contact',
    mainContentReaderLink: 'Skip to main content',
    offlineLabel: 'You are currently offline. Some features of the site may not work correctly.',
    impersonationLabel: 'You are currently impersonating "$1" [userId=$2].', // $1 = subject's name who is being impersonated, $2 = subject's user ID
    searchHeaders: {
      paper: 'Matching Papers',
      author: 'Matching Authors',
    },
    seeAllLabel: 'See All Results for "$1"',
  },

  appFooter: {
    apiLicense: 'API License Agreement',
    corpusApi: 'API and Open Corpus',
    companyName: 'The Allen Institute for AI',
    logoText: 'The Allen Institute for AI',
    tos: 'Terms of Service',
    privacy: 'Privacy Policy',
    research: 'Research',
    publisherPartners: 'Publisher Partnerships',
    dataPartners: 'Data Partnerships',
    creditsPrologue: 'Proudly built by ',
    creditsUsing: ' using these ',
    ai2: 'Ai2',
    collaboratorsAttributions: 'Collaborators & Attributions ',
    collaborators: 'Collaborators',
    attributions: 'Attributions',
    sources: 'Sources',
    sourcesModal: {
      title: 'Sources',
      desc: 'Semantic Scholar uses the following data sources:',
      callToAction: 'Interested in adding your data to our index?',
      feedbackButtonLabel: 'Let Us Know',
    },
    fontAwesomeStatement: 'Icons from ',
    fontAwesome: 'Font Awesome',
    martiH: 'Marti Hearst',
    martiHStatement: 'UC Berkeley',
    reinaldoB: 'Reinaldo Bergamaschi',
    concatenatorText: 'and the ',
    odySci: 'OdySci Academic',
    team: 'team',
    leeG: 'Lee Giles',
    citeSeer: 'CiteSeer',
    paulG: 'Paul Ginsparg',
    paulGStatement: 'arXiv',
    johnB: 'John Bohannon',
    johnBStatement: 'Science',
    microsoftR: 'Microsoft Research',
    microsoftA: 'Microsoft Academic',
  },

  loadingPage: {
    defaultLoadingMessage: 'Loading...',
    defaultErrorMessage:
      'Failed to load the requested page. Try refreshing your browser to see the page.',
  },

  componentLibrary: {
    form: {
      fieldLabel: {
        optionalLabel: 'Optional',
      },
    },
    modal: {
      closeAriaLabel: 'Close',
    },
    pager: {
      ariaLabelNextPage: 'next page',
      ariaLabelNavigateToPage: 'navigate to page $1', // $1 page number
      ariaLabelNavigateCurrentPage: 'currently on page $1', // $1 page number
      ariaLabelPrevPage: 'previous page',
    },
  },

  cookieBanner: {
    dismissButtonLabel: 'ACCEPT & CONTINUE',
    optOutButtonLabel: 'or Only Accept Required',
    privacyPolicyLabel: 'Privacy Policy',
    termsOfServiceLabel: 'Terms of Service',
    datasetLicenseLabel: 'Dataset License',
    bodyText:
      'By clicking accept or continuing to use the site, you agree to the terms outlined in our',
  },

  sampleQueries: {
    try: 'Try:',
    queryAriaLabel: 'Try searching for $1', // $1 = query
  },

  search: {
    emptyResults: {
      headline: 'No Papers Found',
      suggestionText: 'Did you mean',
      removeFacetsLabel: 'Try removing the last applied filter.',
      labelWithFacets: 'Sorry, there are no results for <em>$1</em> with those filters applied.', // $1 = query
      labelWithFacetsNoQuery: 'Sorry, there are no results with those filters applied.',
      labelWithQuery: 'Sorry, there are no results for <em>$1</em>', // $1 = query
      labelWithoutQuery: 'Sorry, there are no results.',
    },
  },

  searchBar: {
    searchText: 'Search $1 papers from all fields of science', // $1 = number of papers in the corpus
    searchTextMobile: 'Search $1 papers', // $1 = number of papers in the corpus
  },

  personalizedCue: {
    authoredLibraryPaperCue: {
      label: {
        singular: 'Authored a Paper in Your Library',
        plural: 'Authored $1 Papers in Your Library',
      },
      cueDescription: 'Personalized based on your library',
    },
    citedByLibraryPaperCue: {
      label: {
        singular: 'Cited by a paper in your library',
        plural: 'Cited by $1 papers in your library',
      },
      cueDescription: 'Personalized based on your library',
    },
    citesLibraryPaperCue: {
      label: {
        singular: 'Cites a paper in your library',
        plural: 'Cites $1 papers in your library',
      },
      cueDescription: "Builds on papers you've shown interest in before",
    },
    citesYourPaperCue: {
      label: {
        singular: 'Cites one of your papers',
        plural: 'Cites $1 of your papers',
      },
      cueDescription: 'Personalized based on your authored papers',
    },
    coauthorCue: {
      label: {
        singular: 'Co-Authored a Paper With You',
        plural: 'Co-Authored $1 Papers With You',
      },
      cueDescription: 'Personalized based on your authored papers',
    },
    youCitedAuthorCue: {
      label: {
        singular: 'Authored a Paper You Cited',
        plural: 'Authored $1 Papers You Cited',
      },
      cueDescription: 'Personalized based on your authored papers',
    },

    morePapersLabel: {
      singular: '+$1 More Paper',
      plural: '+$1 More Papers',
    },
    learnMore: 'Learn more',
    inYourLibrary: 'In Your Library',
  },

  metaDescription: {
    paperFallback: {
      content: 'Semantic Scholar extracted view of "$1" by $2', // $1 = paper title, $2 = authors
    },

    paperFigure: {
      content: '$1 - "$2"', // $1 = figure caption, $2 = paper title
    },

    authorHomePage: {
      content: 'Semantic Scholar profile for $1, with $2 scientific research papers.', // $1 = author name, $2 = paper count
      contentInfluential:
        'Semantic Scholar profile for $1, with $2 highly influential citations and $3 scientific research papers.', // $1 = author name, $2 = influential citation count, $3 = paper count
    },

    crawlerPage: {
      content: 'Information about the Semantic Scholar web crawler.',
      title: 'Semantic Scholar Bot',
    },

    general: {
      content:
        'An academic search engine that utilizes artificial intelligence methods to provide highly relevant results and novel tools to filter them with ease.',
    },
  },

  error: {
    'general-error': 'Sorry, something went wrong. Please try again later.',
    'bad-email-address':
      'Looks like you entered an invalid email. Please check your email and try again.',
    whitepage: {
      pageTitle: 'Error: Something went wrong | Semantic Scholar',
      description:
        'Something on Semantic Scholar broke unexpectedly. Our team has been notified and will look into it, in the meantime please try refreshing the page. If you need more help, reach out to us at <a href="mailto:feedback@semanticscholar.org">feedback@semanticscholar.org</a>',
    },
  },

  pageNotFound: {
    imageAltText: 'Page Not Found',
    mainText: "We can't find the page you're looking for.",
    subText: 'You may have mistyped the address or the page has been moved.',
    codeText: 'Error: 404',
    backButtonLabel: 'Go back to home page',
  },

  serverError: {
    imageAltText: 'Page Not Found',
    mainText: 'Our servers are having a bit of trouble.',
    subText: 'Our engineers are on the case and will have things back to normal shortly.',
    codeText: 'Error: $1', // $1 is the status code
    backButtonLabel: 'Go back to home page',
  },

  abstract: {
    empty: {
      text: "Sorry, we couldn't extract an abstract for this paper.",
    },
  },

  account: {
    menu: {
      accountLabel: 'Account',
      adminLabel: 'Admin Tools',
      accountMenuAriaLabel: 'Account Menu',
      authorProfileLabel: 'Your Author Page',
      componentLibraryLabel: 'Component Library',
      libraryLabel: 'Library',
      moderationLabel: 'Moderation Tools',
      researchDashboardLabel: 'Research Dashboard',
      researchFeedsLabel: 'Research Feeds',
      settingsLabel: 'Settings',
      signInLabel: 'Sign In',
      signOutLabel: 'Sign Out',
      signUpLabel: 'Create Free Account',
      spritesLabel: 'Sprite Listing',
    },
    mobileMenu: {
      research: {
        header: 'Research',
        dashboard: 'Research Dashboard',
        library: 'Library',
        allPapers: 'All Papers',
        unsortedPapers: 'Unsorted Papers',
      },
      settings: {
        header: 'General Settings',
        account: 'Your Account',
        contact: 'Contact Preferences',
        alert: 'Alert Preferences',
      },
      faq: 'FAQ',
      about: 'About',
      contact: 'Contact',
      signOut: 'Sign Out',
    },
    fallbackPageTitle: 'Account Settings | Semantic Scholar',
    section: {
      authorPage: {
        navLabel: 'Author Page Settings',
      },
      general: {
        navLabel: 'General Settings',
      },
    },
    button: {
      submit: 'SUBMIT',
    },
    page: {
      alerts: {
        navLabel: 'Alert Preferences',
        pageHeading: 'Alert Preferences',
        pageTitle: 'Alert Preferences | Semantic Scholar',
      },
      authorClaimPending: {
        navLabel: 'Claim Status',
        pageHeading: 'Claim Status',
        pageTitle: 'Claim Status | Semantic Scholar',
      },
      authorContact: {
        navLabel: 'Display Information',
        pageHeading: 'Display Information',
        pageTitle: 'Display Information | Semantic Scholar',
        pageDescription:
          'Verify the information displayed on your <a href="$1">author page</a>.  It may take up' + // $1 = path to user's author page
          ' to 24 hours for changes to be reflected throughout Semantic Scholar. If you have' +
          ' any questions or need assistance, please  <a' +
          ' href="mailto:feedback@semanticscholar.org?subject=Author%20Page%20Settings%20Display%20Information">contact us</a>.',
      },
      authorUnclaimSuccess: {
        pageHeading: 'Author Page Unclaimed',
        pageTitle: 'Author Page Unclaimed | Semantic Scholar',
        pageDescription:
          'Your Author Page has been unclaimed successfully. If this was in error, you can reclaim by navigating to the Author Page and claiming again.',
        authorLinkLabel: 'View your old Author Page',
      },
      corrections: {
        navLabel: 'Status of Changes',
        pageHeading: 'Status of Changes',
        pageTitle: 'Status of Changes | Semantic Scholar',
        pageDescription:
          'Successful changes are applied directly to your <a href="$1">author page</a>.', // $1 = path to user's author page
        pageDescriptionNoAuthorLink: 'Successful changes are applied directly to your author page.', // $1 = path to user's author page
        authorPageLink: 'GO TO AUTHOR PAGE',
      },
      contact: {
        navLabel: 'Contact Preferences',
        pageHeading: 'Contact Preferences',
        pageTitle: 'Contact Preferences | Semantic Scholar',
        subscriptionLabel: 'Subscriptions',
        email: {
          label: 'Email Address',
          buttonLabel: 'Update Email Address',
          updateLabel: 'Update',
          cancelLabel: 'Cancel',
          emailInUseError: 'This email is already in use.',
          noEmailError: 'An email must be set to modify subscriptions.',
          invalidEmailError: 'This email is invalid.',
        },
        unsubscribe: {
          label: 'Unsubscribe From All Subscriptions',
          buttonLabel: 'Unsubscribe',
          description:
            'Control whether to receive emails from Semantic Scholar. You will still' +
            ' receive alert emails if this setting is off, <a href="/me/account/alerts">go here' +
            ' to manage your alert emails</a>.',
        },
        subscriptionType: {
          allenInstituteNewsletterSubscription: {
            label: 'Allen Institute for AI Newsletter',
            description:
              'Semantic Scholar is a project out of the nonprofit Allen Institute for AI (AI2). Stay informed about new research, news coverage, upcoming events, featured teams, and more from AI2.',
          },
          semanticScholarNewsletterSubscription: {
            label: 'Semantic Scholar Newsletter',
            description: 'Stay informed about new content and research on Semantic Scholar.',
          },
          productFeaturesSpotlightSubscription: {
            label: 'Product Spotlight',
            description: 'Stay up to date with the latest features on Semantic Scholar.',
          },
          developerUpdatesSubscription: {
            label: 'Developer Updates',
            description: 'News about the Semantic Scholar API and Open Corpus.',
          },
          accessibilityUpdatesSubscription: {
            label: 'Accessibility Updates',
            description:
              'Updates on accessibility research and prototypes from the Semantic Scholar team.',
          },
        },
      },
      addPapers: {
        navLabel: 'Add Papers',
        pageHeading: 'Add Papers',
        pageTitle: 'Add Papers | Semantic Scholar',
        pageDescription:
          'It may take up to 24 hours for authorship changes to be reflected throughout Semantic Scholar and your <a href="$1">author page</a>.' + // $1 = path to user's author page
          ' For new paper index requests, please allow 2 weeks for processing. If you need assistance, please' +
          ' <a href="mailto:feedback@semanticscholar.org?subject=Claim%20Author%20Page:%20Add%20Papers">contact us</a>.',
        pageDescriptionNoAuthorLink:
          'It may take up to 24 hours for authorship changes to be reflected throughout Semantic Scholar and your author page.' +
          ' For new paper index requests, please allow 2 weeks for processing. If you need assistance, please' +
          ' <a href="mailto:feedback@semanticscholar.org?subject=Claim%20Author%20Page:%20Add%20Papers">contact us</a>.',
      },
      manageAccount: {
        navLabel: 'Account Information',
        pageHeading: 'Account Information',
        pageTitle: 'Account Information | Semantic Scholar',
        pageDescription: 'Manage the information provided to Semantic Scholar.',
        submitButtonLabel: 'Submit',
        successMessage: 'Successfully saved!',
      },
      removePapers: {
        navLabel: 'Remove Papers',
        pageHeading: 'Remove Papers',
        pageTitle: 'Remove Papers | Semantic Scholar',
        pageDescription:
          'It may take up to 24 hours for changes to be reflected throughout' +
          ' Semantic Scholar. Please note that removing a paper from your <a href="$1">author page</a> will not' + // $1 = path to user's author page
          ' remove the paper from the Semantic Scholar corpus. If you need assistance, <a href="mailto:feedback@semanticscholar.org?subject=Author%20Page%20Settings:%20Remove%20Papers">contact us</a>.',
        pageDescriptionNoAuthorLink:
          'It may take up to 24 hours for changes to be reflected throughout' +
          ' Semantic Scholar. Please note that removing a paper from your author page will not' +
          ' remove the paper from the Semantic Scholar corpus. If you need assistance, <a href="mailto:feedback@semanticscholar.org?subject=Author%20Page%20Settings:%20Remove%20Papers">contact us</a>.',
      },
    },
  },

  authorClaimPending: {
    description: 'Thank you for submitting a request to claim an author page on Semantic Scholar.',
    bannerLabel: 'Your author page claim is currently in moderation',
    statusInstruction: `Your request has been received and will be moderated by a member of our team. You can expect to hear back about your request in 4-5 business days. Please don't hesitate to let us know if you have any additional questions or concerns in the meantime.`,
    authorLinkLabel: 'View Claimed Author Page',
  },

  login: {
    closeModalAriaLabel: 'Close login modal',
    descriptionHeader: 'Explore. Discover. Achieve Impact.',
    descriptionText:
      'Build your research library and stay up to date by creating alerts for the authors, topics, and papers that pertain to your research.',
    alertDescriptionHeader: 'Stay up-to-date with the latest research',
    alertDescriptionText:
      'Save research to your library, create author and topic alerts, and more.',
    moreOptionsLabel: 'View Additional Options',
    signIn: {
      greetingText: 'Sign In',
      switchLoginTypeLinkLabel: `Don't have an account? `,
      switchLoginTypeLinkText: ' Create a free account',
      submitButtonLabel: 'Sign In',
      loginMethodLabel: 'Sign In With $1', // $1 = Login provider name
      termsAndPrivacy:
        'By signing in, you accept our <a href="https://allenai.org/terms.html" target="_blank">Terms of Service</a> & <a href="https://allenai.org/privacy-policy.html" target="_blank">Privacy Policy</a>.',
      termsAndPrivacyMobile:
        'By signing in, you accept our <br> <a href="https://allenai.org/terms.html" target="_blank">Terms of Service</a> & <a href="https://allenai.org/privacy-policy.html" target="_blank">Privacy Policy</a>.',
      error: {
        invalidEmail: 'Email cannot contain whitespace.',
      },
    },
    signUp: {
      greetingText: 'Join Semantic Scholar',
      switchLoginTypeLinkLabel: 'Already have an account? ',
      switchLoginTypeLinkText: ' Sign In.',
      loginMethodLabel: 'Sign Up With $1', // $1 = Login provider name
      termsAndPrivacy:
        'Click "Sign up" to accept the <a href="https://allenai.org/terms.html" target="_blank">Terms of Service</a> & <a href="https://allenai.org/privacy-policy.html" target="_blank">Privacy Policy</a>.',
      termsAndPrivacyMobile:
        'Click "Sign up" to accept the <br> <a href="https://allenai.org/terms.html" target="_blank">Terms of Service</a> & <a href="https://allenai.org/privacy-policy.html" target="_blank">Privacy Policy</a>.',
      institutionalLoginTooltip: 'Institutional login support through Open Athens',
      institutionalLoginAriaLabel: 'Visit our FAQ to learn more about institutional login',
      twitterLoginTooltip:
        'We no longer support Twitter authentication logins. Visit our FAQ for details.',
      twitterLoginAriaLabel: 'Visit our FAQ to learn more about Twitter login',
    },
    error: {
      unknownError: 'An error occurred during the login process. Please try again.',
      missingEmailPassword:
        'Email and password are required to login without using social provider.',
      networkFailure: 'An error occurred during the login process. Please try again.',
      cancelledByUser: 'You did not sign into the login provider. Please try again.',
      forbiddenByProvider:
        'The login provider says your account is not allowed to access this site. Please try again with another account.',
    },
    loginForm: {
      emailPlaceholder: 'Email Address',
      passwordPlaceholder: 'Password',
      passwordResetLabel: 'Forgot Password?',
      ruleLabel: 'OR',
    },
    staticLoginPage: {
      signInHeaderText: 'Sign in to continue',
    },
    method: {
      cognito: {
        name: 'Email',
        methodName: 'Cognito',
        signInLabel: 'Sign In With Email',
        signUpLabel: 'Sign Up With Email',
        error: {
          signIn: 'There was a problem signing in. Try again.',
          signUp: 'There was a problem signing up. Try again.',
        },
      },
      google: {
        name: 'Google',
        methodName: 'Google',
        signInLabel: 'Sign In With Google',
        signUpLabel: 'Sign Up With Google',
        error: {
          signIn: 'There was a problem signing in. Try again.',
          signUp: 'There was a problem signing up. Try again.',
        },
      },
      // Dummy property added to fix lint errors. Google One Tap is not an option on the login modal
      googleOneTap: {
        signInLabel: 'Sign In With Google',
        signUpLabel: 'Sign Up With Google',
      },
      facebook: {
        name: 'Facebook',
        methodName: 'Facebook',
        signInLabel: 'Sign In With Facebook',
        signUpLabel: 'Sign Up With Facebook',
        error: {
          signIn: 'There was a problem signing in. Try again.',
          signUp: 'There was a problem signing up. Try again.',
        },
      },
      twitter: {
        name: 'Twitter',
        methodName: 'Twitter',
        signInLabel: 'Sign In With Twitter',
        signUpLabel: 'Sign Up With Twitter',
        error: {
          signIn: 'There was a problem signing in. Try again.',
          signUp: 'There was a problem signing up. Try again.',
        },
      },
      openathens: {
        name: 'Institution',
        methodName: 'Open Athens',
        signInLabel: 'Sign In With Your Institution',
        signUpLabel: 'Sign Up With Your Institution',
        error: {
          signIn:
            'There was a problem signing in with your institution. To continue, choose a different service.',
          signUp:
            'There was a problem signing up with your institution. To continue, choose a different service.',
        },
      },
    },
  },

  demographicForm: {
    closeModalAriaLabel: 'Close confirm account details modal',
    createAccountBtnLabel: 'SUBMIT',
    currentRoleLabel: 'What Is Your Current Role?',
    emailLabel: 'Email Address',
    lastNameLabel: 'Family Name',
    fieldsOfStudyLabel: 'What Are Your Fields Of Study?',
    genderLabel: 'Gender',
    firstNameLabel: 'Given Name',
    createAnAccountText: 'Confirm Account Details',
    stayConnectedText: 'Stay connected with Semantic Scholar.',
    tellAboutYourselfText: 'Tell Us About Yourself',
    tellAboutYourselfSubText:
      'This information will help us to better understand our users, and will not be shared with anyone',
    optInMarketingText:
      'I would like to stay up-to-date with new features, beta tests, and announcements.',
    optionalLabel: 'Optional',
    websiteUrlLabel: 'Academic Website',
    ageRangeLabel: 'Age Range',
    termsAndConditionsText:
      'I accept the <a href="https://allenai.org/terms.html" target="_blank">Terms of Service</a> and <a href="https://allenai.org/privacy-policy.html" target="_blank">Privacy Policy</a>.',
    error: {
      apiFailed: 'An error occurred while submitting this form. Please try again.',
      missingFirstName: 'Required',
      missingLastName: 'Required',
      missingEmail: 'Required',
      emailInvalid: 'Email Is Invalid (e.g. user@gmail.com).',
      emailTaken: 'This email is already in use.',
      submitErrorMessage: 'To continue, correct the marked fields.',
      missingTermsAndConditions: 'Please accept the Terms and Conditions to continue.',
    },
  },

  figures: {
    empty: {
      text: "Sorry, we couldn't extract any figures or tables for this paper.",
    },
  },

  citations: {
    statistics: 'Citation Statistics',
    influential: {
      label: 'Highly Influential Citation',
      linkAriaLabel: 'Visit citations for this paper filtered by highly influential',
      ariaLabel: 'Highly Influenced',
      labelPlural: 'Highly Influential Citations',
      // TODO(codeviking): We need to consolidate our mechanism for labeling across clients
      // and figure out how to handle plural vs non-plural labels across the board
      'mobile-label': 'highly influential citation',
      text: '<p class="cl-paper-stats__influential-popover-content"><a href="/faq#influential-citations" class="cl-paper-stats__influential-popover-content__link"><u>Learn more</u> about how we identify influential citations.</a></p>',
      explanation: '<u>Learn more</u> about how we identify influential citations.',
    },
    citation: ' Citation',
    citationLinkAriaLabel: 'Visit citations for this paper',
    lastYear: 'Cited $1 in the last $2.',
    estimatedTotal: {
      label: 'Citations',
      insufficientData:
        'Semantic Scholar was not able to find enough data to provide a more specific estimate.',
    },
    totalByYear: {
      label: 'Citations per Year',
    },
    estimateFootnote: {
      text: '*  Automatically estimated by Semantic Scholar based on the available data.',
    },
    empty: {
      text: "Sorry, we couldn't extract any citations for this paper.",
    },
    emptyFiltered: {
      text: 'No Matching Citations',
    },
    emptySearchWithin: {
      text: 'Sorry, there are no results with those filters applied.',
      removeFacetsLabel: 'Try removing the last applied filter.',
    },
    'few-citations': {
      text: 'Fewer than 50 Citations',
    },
    'no-recent-citations': {
      text: 'Not enough recent citations',
    },
    excerpts: {
      singular: '$1 Excerpt', // $1 is number of citations contexts
      plural: '$1 Excerpts', // $1 is number of citations contexts
    },
    excerptIntentLabel: {
      methodology: 'Cites methods from ',
      background: 'Cites background from ',
      result: 'Cites results from ',
      none: 'Additional Excerpts',
    },
    histogram: {
      popoverLabel: 'Citation',
      desktop: {
        title: 'Filter Citations By Year',
      },
      mobile: {
        title: 'Filter Citations',
        label: 'CITATIONS BY YEAR',
      },
    },
    stats: {
      desktop: {
        title: 'Citation Statistics',
      },
    },
    intents: {
      tooltip:
        'Semantic Scholar classifies citations by their intent: surfacing flags that indicate when a citation has cited the methods, background or extended the results of the cited paper. For a more in-depth explanation, see <a href="https://www.semanticscholar.org/paper/Structural-Scaffolds-for-Citation-Intent-in-Cohan-Ammar/95ebe37c856e914b760bc5db63561f461ec444cc">Structural Scaffolds for Citation Intent Classification in Scientific Publications</a>.',
    },
    notInCorpus: 'This paper is not yet in our corpus.',
    highlyInfluenced: {
      citingPapers: 'Highly Influenced',
      citedPapers: 'Highly Influential',
    },
    popup: {
      citingPapers: {
        title: '"$1" Highly Influenced This Citation',
        content:
          'Semantic Scholar predicted that this citation was highly influenced by evaluating the number of times it was cited, as well as the location and context of the citation. For a more in-depth explanation, see <a href="/paper/Identifying-Meaningful-Citations-Valenzuela-Ha/d5641d684df3a9e7c357923d109421fe4304ffa8">Identifying Meaningful Citations</a>.',
      },
      citedPapers: {
        title: 'This Reference Was Highly Influential To "$1"',
        content:
          'Semantic Scholar predicted that this reference was highly influential by evaluating the number of times it was referenced, as well as the location and context of the reference. For a more in-depth explanation, see <a href="/paper/Identifying-Meaningful-Citations-Valenzuela-Ha/d5641d684df3a9e7c357923d109421fe4304ffa8">Identifying Meaningful Citations</a>.',
      },
    },
  },

  references: {
    excerptIntentLabel: {
      methodology: 'References methods',
      background: 'References background',
      result: 'References results',
      none: 'Additional excerpts',
    },
    empty: {
      text: "Sorry, we couldn't extract any references for this paper.",
    },
  },

  serp: {
    pageTitleWithQuery: '$1 | Semantic Scholar', // $1 = Search query
    pageTitleWithoutQuery: 'Search | Semantic Scholar',
    histogram: {
      title: 'Results by year',
      button: {
        thisYear: {
          title: 'This year',
        },
        lastFive: {
          title: 'Last 5 years',
        },
        lastTen: {
          title: 'Last 10 years',
        },
      },
    },
    toggle: {
      review: {
        title: 'Lit Reviews',
      },
      year: {
        title: 'Last Five Years',
      },
      compact: {
        title: 'Compact',
      },
      comfortable: {
        title: 'Comfortable',
      },
    },
    matchedAuthor: {
      stats: {
        publications: {
          singular: '$1 Publication', // $1 = number of papers
          plural: '$1 Publications', // $1 = number of papers
        },
        citations: {
          singular: '$1 Citation', // $1 = number of total citations
          plural: '$1 Citations', // $1 = number of total citations
        },
      },
    },
  },

  filter: {
    all: {
      title: 'All Types',
      label: 'All Types',
      description: 'Display citations that match all types.',
    },
    result: {
      title: 'Results Citations',
      label: 'Results Citations',
      description: "Display citations that refer to another paper's results.",
    },
    methodology: {
      title: 'Methods Citations',
      label: 'Methods Citations',
      description: "Display citations that refer to another paper's methodology.",
    },
    background: {
      title: 'Background Citations',
      label: 'Background Citations',
      description: 'Display citations that refer to another paper as background.',
    },
  },

  socialShareOptions: {
    shareButtonLabel: {
      sharePaper: 'Share This Paper',
      share: 'Share',
      directShareHoverText: 'Copy a shareable link to your clipboard',
      directShareHoverTextCopied: 'Copied',
      twitterAriaLabel: 'Share on Twitter',
      facebookAriaLabel: 'Share on Facebook',
      directLinkAriaLabel: 'Copy direct link to page',
      emailAriaLabel: 'Email paper',
    },
  },

  filterBar: {
    activeFilters: '+ filters',
    approxResultCount: 'About $1 results for “$2” $3', // $1 = approx. result count, $2 = query text, $3 hasFilters text
    exactResultCount: '$1 results for “$2” $3', // $1 = approx. result count, $2 = query text, $3 hasFilters text
    filterLabels: {
      date: 'Dates',
      coAuthor: 'Co-Author',
      venue: 'Journal/Conference',
      hasPdf: 'Has PDF',
      sortByRecency: 'Sort By Recency',
    },
    dropdownLabels: {
      citationType: 'Citation Type',
      date: 'Date Range',
      author: 'Author',
      coAuthor: 'Co-Author',
      venue: 'Journals & Conferences',
      moreFilters: 'More Filters',
      allFilters: 'Filters',
      showFilters: 'Show Filters',
      applyFilters: 'Apply Filters',
      clearFilters: 'Clear Filters',
      clear: 'Clear',
      clearedFilters: 'Cleared Filters',
    },
    dropdownTitles: {
      fieldOfStudy: 'Fields of Study',
      coAuthor: 'Co-Author',
      author: 'Authors',
      venue: 'Top Journals & Conferences',
    },
    singleResultCount: 'One result for “$1” $2', // $1 = query text, $2 hasFilters text
    shortLabels: {
      fieldsOfStudy: 'Field',
      authors: 'Author',
      venue: 'Journal',
      coAuthors: 'Co-Author',
    },
    sort: {
      citationCountDescription: 'Top 100 relevant results, sorted by citation count',
      influenceCountDescription: 'Top 100 relevant results, sorted by most influential',
      recencyDescription: 'Top 100 relevant results, sorted by date',
    },
    densityToggle: {
      denseViewAria: 'compact search results view',
      regularViewAria: 'standard search results view',
    },
  },

  filterCitationBar: {
    approxResultCount: 'About $1 Citations', // $1 = approx. result count
    clearDate: 'Reset Date Filter',
    dateRange: '$1 - $2', // $1 = min year, $2 = max year
    exactResultCount: '$1 Citations', // $1 = approx. result count
    moreThanTenThousand: 'Over 10,000 Citations',
    noResultCount: 'Citations',
    searchPlaceholder: 'Search authors, publications, venues, fields of study',
    singleResultCount: 'One Citation',
  },

  filterReferenceBar: {
    approxResultCount: 'About $1 References', // $1 = approx. result count
    exactResultCount: '$1 References', // $1 = approx. result count
    moreThanTenThousand: 'Over 10,000 References',
    noResultCount: 'References',
    singleResultCount: 'One Reference',
  },

  sort: {
    label: 'Sort by $1',
    influence: {
      title: 'Highly Influential Citations',
      label: 'Most Influential Papers',
      description:
        'Display publications with more influential citations first. Semantic Scholar identifies citations where the cited publication has a significant impact on the citing publication, making it easier to understand how publications build upon and relate to each other. Learn more about our approach <strong><a href="/paper/Identifying-Meaningful-Citations-Valenzuela-Ha/d5641d684df3a9e7c357923d109421fe4304ffa8">here</a></strong>.',
    },
    'total-citations': {
      title: 'Citations',
      label: 'Citation Count',
      description:
        'Display publications with more estimated citations first. Semantic Scholar estimates the total number of citations based on a machine-learned model. Learn more in our <strong><a href="/faq#estimated-citations">FAQ.</a></strong>',
    },
    year: {
      title: 'Recency',
      label: 'Recency',
      description:
        'Sort publications based upon the year they were published, showing recently published publications first.',
    },
    'pub-date': {
      title: 'Recency',
      label: 'Recency',
      description:
        'Sort publications based upon the date published, showing recently published publications first.',
    },
    relevance: {
      title: 'Relevance',
      label: 'Relevance',
    },
    'is-influential': {
      title: 'Most Influenced Papers',
      label: 'Most Influenced Papers',
    },
    alphabetical: {
      label: 'Alphabetical',
    },
    date_added_to_library: {
      label: 'Date Added to Library',
    },
    recency: {
      label: 'Recency',
    },
    mobileCloseAriaLabel: 'Close sort modal',
  },

  author: {
    landingPage: {
      title: 'Authors | Semantic Scholar',
    },
    pageTitleWithAuthor: '$1 | Semantic Scholar',
    pageTitleWithoutAuthor: 'Semantic Scholar',
    loading: 'Loading author…',
    alerts: {
      header: 'Create Alerts For This Author',
      papers: 'Alerts On New Papers',
      citations: 'Alerts On New Citations',
    },
    authorList: {
      citing: {
        emptyLabel: 'There are no known authors citing',
      },
      coauthor: {
        emptyLabel: 'There are no known co-authors for',
      },
      referenced: {
        emptyLabel: 'There are no known authors referenced by',
      },
      defaultEmptyListAuthorName: 'this author',
    },
    buttonLabel: {
      followAuthor: 'Follow Author...',
      claimProfile: 'Claim Author Page',
      editProfile: 'Edit Author Page',
      pendingStatus: 'Claim Pending',
      claimInfoAriaLabel: 'Learn more about claiming an author page.',
    },
    pendingBannerLabel: 'Your claim is pending',
    profilePictureAltText: 'Author Profile Picture',
    card: {
      publications: 'Publications',
      citations: 'Citations',
      influentialCitations: 'Highly Influential Citations',
      hIndex: 'h-index',
      hIndexAriaLabel: 'Learn more about h-index',
      alertsAriaLabel: 'Create alerts for this author',
      ahpExplanationText:
        'Author pages are created from data sourced from our academic publisher partnerships and public sources.',
      ahpExplanationShowMore: 'show more',
      claimPopoverText:
        'Claim your author page to add your affiliation, update your name, manage your papers, and more.',
      alreadyClaimedPopoverDesc:
        ' This author page has been claimed and verified. Claiming allows you to update your contact information and manage your papers',
      alreadyClaimedPopoverHeader: 'Claimed & Verified',
      alreadyClaimedPopoverLink: 'Learn more',
      verifiedButtonAriaLabel: 'Verified author',
      viewAuthorsPublicationsButton: "View Author's Publications",
    },
    dataNotice: {
      buttonLabel: 'About This Data',
      headline: 'About This Data',
      noticeText: 'Citing, Referenced, and Co-author lists are refreshed on a weekly basis.',
      ctaLabel: 'Something not right?',
      ctaButton: 'Contact Us',
    },
    headers: {
      highlighted: 'Highlighted Publications',
      allPubs: 'All Publications',
      coAuthors: 'Top Co-authors by Year',
      influence: 'Influence',
    },
    tabs: {
      details: 'Details',
      papers: 'Publications',
      coAuthors: 'Co-Authors',
      citingAuthors: 'Citing Authors',
      referencedAuthors: 'Referenced Authors',
    },
    influential: {
      label: 'Highly Influential Citation',
      pluralLabel: 'Highly Influential Citations',
      explanation:
        'Semantic Scholar identifies citations where the cited publication has a significant impact on the citing publication, making it easier to understand how publications build upon and relate to each other.',
      explanationExtra:
        'For more information, please <strong><a href="/faq#influential-citations">visit our FAQ</a></strong>.',
    },
    recommendations: {
      headline: 'Recommended Authors',
      headlineCoAuthors: 'Co-Authors',
      coAuthorLabel: 'Co-author',
      counts: {
        publication: 'Publication',
        citation: 'Citation',
        formattedCounts: '$1 • $2', // $1 = publication count • $2 = citation count
      },
      viewAllCoAuthors: 'View All Co-Authors',
    },
    hIndex: {
      label: 'H-index',
      explanation:
        'The h-index is based on a researcher’s total number of publications and the citations for those publications.',
    },
    mobileText: {
      searchButton: 'See publications by this author',
      underConstruction:
        'We’re hard at work designing a better mobile experience for our author pages. In the meantime, please try visiting this page on a larger device.',
    },
    correctionPending: {
      label:
        'The changes you have submitted are being processed. It may take up to 24 hours for them to be reflected on the site.',
    },
    searchPlaceholder: 'Search authors, publications, venues, fields of study',
  },

  authorRow: {
    badges: {
      citingAuthor: {
        label: {
          singular: '$1 Paper Citing $2', // $1 = number of citations, $2 = referenced author name
          plural: '$1 Papers Citing $2', // $1 = number of citations, $2 = referenced author name
        },
      },
      coAuthorship: {
        label: {
          singular: '$1 Co-Authored Paper', // $1 = number of papers
          plural: '$1 Co-Authored Papers', // $1 = number of papers
        },
      },
      referencedAuthor: {
        label: {
          singular: '$1 Paper Referenced by $2', // $1 = number of references, $2 = citing author name
          plural: '$1 Papers Referenced by $2', // $1 = number of references, $2 = citing author name
        },
      },
    },
    meta: {
      citations: {
        label: {
          singular: '1 Citation',
          plural: '$1 Citations', // $1 = number of papers
        },
      },
      hIndex: {
        label: '$1 h-index', // $1 = h-index value
      },
      publications: {
        label: {
          singular: '1 Publication',
          plural: '$1 Publications', // $1 = number of papers
        },
      },
    },
  },

  authorInfluence: {
    authorCountLabel: {
      singular: '1 Author',
      plural: '$1 Authors', // $1 = number of authors
    },
    citingAuthors: {
      headline: {
        label: 'Authors Citing $1', // $1 = referenced author's name
      },
    },
    coAuthors: {
      headline: {
        label: "$1's Co-Authors", // $1 = author name
      },
    },
    referencedAuthors: {
      headline: {
        label: 'Authors $1 Has Referenced', // $1 = citing author's name
      },
    },
  },

  authorClaim: {
    pageTitle: {
      withName: 'Claim $1 | Semantic Scholar', // $1 = author name
      withoutName: 'Claim Author Page | Semantic Scholar',
    },
    headline: {
      withName: 'Claim Author Page: $1', // $1 = author name
      withoutName: 'Claim Author Page',
    },
    subhead: 'Please provide the following information to help us moderate your claim.',
    bodyText: [
      'We will notify you via contact email when this request has been moderated. Once your request has been approved, you can edit the information on your author page.',
    ],
    form: {
      affiliationLabel: 'Affiliations (separated by semicolon)',
      affiliationPlaceholder: 'Allen Institute for AI; ...',
      alertEmailOptInLabel:
        'Notify me when new papers and citations are added to my author page. <a href="https://allenai.org/privacy-policy.html" target="_blank">Privacy Policy</a>',
      claimEmailChangeLabel: 'Change',
      claimEmailLabel: 'Contact Email (For receiving notifications about your claim request)',
      claimEmailPlaceholder: 'Your Email',
      currentRoleLabel: 'Current Role',
      fieldOfStudyLabel: 'Field of Study',
      fullNameLabel: 'Full Name',
      fullNamePlaceholder: 'Your Name',
      homepageUrlLabel: 'Homepage',
      homepageUrlPlaceholder: 'e.g. https://allenai.org',
      loginEmailLabel: 'Account Email',
      loginEmailHelpText: '',
      optional: 'Optional',
      orcidIdLabel: 'ORCiD ID',
      orcidIdPlaceholder: 'e.g 0000-0002-1825-0097',
      submitLabel: 'Submit Claim',
      termsOfServiceLabel: `I agree that all information I have provided is true and accurate to the best of my knowledge, and provided in accordance with AI2's <a href="https://allenai.org/terms.html" target="_blank">Terms of Service</a> and <a href="https://allenai.org/privacy-policy.html" target="_blank">Privacy Policy</a>.`,
      userCommentLabel: 'Additional information',
      userCommentPlaceholder:
        'Please include additional information to help us expedite your claim.',
    },
    error: {
      apiFailed: 'An error occurred while submitting this page. Please try again.',
      claimAlreadyApproved:
        'You have already successfully claimed an author page. If there is an issue, please reach out to us via our contact form.',
      claimAlreadyPending:
        'You currently have a claim pending. If you believe this is an error, please reach out to us through our contact form.',
      missingAuthor: 'An error has occurred. Please reload the page and try again.',
      missingEmail: 'Email is required.',
      missingName: 'Name is required.',
      missingTermsOfService:
        'You must agree to the latest Terms of Service and Privacy Policy to continue',
      homepageUrlInvalid: 'Homepage is not a valid URL.',
      orcidIdInvalid: 'ORCiD ID is invalid.',
      emailInvalid: 'Email is invalid (e.g. user@gmail.com).',
      createAlertErrorMsg:
        'There was an error subscribing to email alerts for this author. You' +
        ' can try resubscribing for alerts back on the Author Page and manage your alerts in the' +
        ' <a href="/me/account/alerts" target="_blank">Alerts Menu</a>.',
    },
    success: {
      headline: 'Your Request Has Been Received.',
      bodyText:
        'Your request will be moderated by a member of our team. You can expect to hear back about your request via email in 4 - 5 business days. Once your request has been approved, you can edit the information on your author page.',
      authorPageLinkLabel: 'Return to Author Page',
      homePageLinkLabel: 'Return to Home Page',
    },
  },

  authorClaimComplete: {
    pageTitle: 'Claim Author Pending | Semantic Scholar',
  },

  authorshipModeration: {
    pageTitle: 'Authorship Correction Moderation | Semantic Scholar',
    buttons: {
      approveLabel: 'APPROVE',
      denyLabel: 'DENY',
    },
    error: {
      apiFailed: 'An error occurred while submitting this page. Please try again.',
    },
  },

  moderatorCorrections: {
    authorCorrectionsTool: {
      pageTitle: 'Author Corrections Tool | Semantic Scholar',
      sectionLabel: 'Author Corrections Tool',
      navLabel: 'Corrections Author Id',
    },
    input: {
      pageTitle: 'Corrections Author Id',
      label: 'Author id to make corrections for',
    },
    contactInfo: {
      pageDescription: 'Change any of the following fields for the author.',
      pageHeading: 'Display Information for $1', // $1 is author's name
    },
    addPapers: {
      pageDescription: "Add papers to the author's page.",
      pageHeading: 'Add Papers for $1', //$1 is author's name
    },
    removePapers: {
      pageDescription: "Remove papers from the author's page.",
      pageHeading: 'Remove Papers for $1', //$1 is author's name
      status: {
        singular: "Removing <strong>$1 Paper</strong> From $2's Author Page", // $1 = number of papers, $2 = Author's name
        plural: "Removing <strong>$1 Papers</strong> From $2's Author Page", // $1 = number of papers,  $2 = Author's name
      },
    },
  },

  userInfo: {
    accountPage: {
      buttonLabel: 'Settings',
      linkLabel: 'Settings',
    },
    authorPage: {
      buttonLabel: 'Author Page',
      myPageLink: 'My Author Page',
    },
  },

  authorClaimModeration: {
    pageTitle: 'Author Claim Moderation | Semantic Scholar',
    profileFields: {
      ai2AuthorId: 'AI2 Author Id',
      affiliation: 'Affiliation',
      firstName: 'First Name',
      lastName: 'Last Name',
      homepageUrl: 'Homepage',
      orcidId: 'Orcid Id',
      claimedAt: 'Claimed on',
      userComments: 'User comments',
      email: 'Email',
      moderationStatus: 'Moderation Status',
    },
    buttons: {
      approveLabel: 'APPROVE',
      denyLabel: 'DENY',
    },
    internalNotesLabel: 'Moderator Internal Notes',
    moderationDenialReason: {
      selectLabel: 'Reason for Denial',
    },
    moderationGutterAriaLabel: 'Drag to modify table width',
    error: {
      apiFailed: 'An error occurred while submitting this page. Please try again.',
    },
    selectClaim: 'Select a claim to moderate',
  },

  authorEdit: {
    pageTitle: {
      withName: 'Edit $1 | Semantic Scholar', // $1 = author name
      withoutName: 'Edit Author Page | Semantic Scholar',
    },
    headline: 'Author Information',
    description: 'Updates to this information will be reflected on your author page.',
    form: {
      affiliationLabel: 'Affiliations (separated by semicolon)',
      affiliationPlaceholder: 'University of Washington; ...',
      applyToPapersDescription:
        'Changes to your name will be reflected on your author page and your publications.',
      applyToPapersLabel: '<b>Yes,</b> change my name on my papers.',
      cancelLabel: 'Cancel',
      firstNameLabel: 'Given Name',
      firstNamePlaceholder: 'First Name',
      gravatarEmailLabel: 'Email For Profile Photo',
      gravatarEmailSupplementalText: 'Edit email address in',
      gravatarNoticeLabel: 'From Gravatar',
      homepageUrlLabel: 'Homepage',
      homepageUrlPlaceholder: 'https://www.semanticscholar.org',
      lastNameLabel: 'Family Name',
      lastNamePlaceholder: 'Last Name, or Surname',
      middleNamesLabel: 'Middle Name(s)',
      middleNamesPlaceholder: '',
      noPronounsLabel: "I don't use pronouns",
      optional: 'Optional',
      orcidIdLabel: 'ORCiD ID',
      orcidIdPlaceholder: 'e.g. 0000-0002-1825-0097',
      pronounsBlankLabel: 'Do not show pronouns on my author page',
      pronounsDescription:
        'Specify your <a href="https://www.glsen.org/activity/pronouns-guide-glsen" target="_blank" aria-label="guide to pronouns">pronouns</a> if you wish to publicly present them on your author page. (<a href="/faq#pronouns">Learn More</a>)',
      pronounsLabel: 'Pronouns',
      pronounsPlaceholder: 'Specify your own',
      saveLabel: 'Save Page',
      unlinkLabel: 'Unclaim Author Page',
      uploadPhotoLabel: 'Add Photo',
    },
    error: {
      apiFailed: 'An error occurred while submitting this page. Please try again.',
      homepageUrlInvalid: 'Homepage is not a valid URL',
      lastNameMissing: 'Family/Last name is required',
      pronounsTooLong: 'Pronouns must be shorter than $1 characters', // $1 = max chars
      noAuthorClaimed:
        'To claim an author page, please start by searching the site for your name, then clicking the Claim Author Page Button.',
      orcidIdInvalid: 'ORCiD ID is invalid (e.g. 0000-0002-1825-0097)',
    },
    unclaimDialogue:
      'Are you sure you want to unclaim this author page? Any pending changes will be cancelled and you will be required to complete the moderation process if you wish to reclaim this page in the future.',
  },

  authorAddPapers: {
    publisher: {
      onlyVenue: 'Published in $', // $1 = venue
      onlyYear: 'Published $1', // $1 = year published
      bothYearAndVenue: 'Published $1 in $2', // $1 = year published, $2 = venue
    },
    noResultsLabel: 'No Papers Found',
    searchLabel: 'Search for papers by Semantic Scholar URL, title, publisher, or author name',
    youTagLabel: 'YOU',
    blankLabel: 'No Papers Added Yet',
    removeAriaLabel: 'Remove this paper',
    positionLabel: 'Add my name to this position',
    error: {
      apiQueryFailed: 'An error occurred while submitting this page. Please try again.',
      apiSubmitFailed: 'An error occurred while saving your corrections. Please try resubmitting.',
      apiIndexRequestSubmitFailed:
        'An invalid url was used to submit an index request. Please modify and try resubmitting.',
      papersInDraft: 'Please tell us what position you should be in on your paper.',
    },
    indexRequests: {
      add: {
        expandLink: 'Add a paper that does not exist in our corpus',
        expandTips: 'View Tips for a successful index request',
        hideTips: 'Collapse tips',
        tips: [
          'Confirm the paper is licensed for open access.',
          'If you are submitting a journal article, confirm that the journal is included in the <a href="http://www.doaj.org" target="_blank" rel="noopener noreferrer">Directory of Open Access Journals</a>.',
          'Include a direct link to a publicly accessible PDF of your paper to a site that does not require signing into an account.',
        ],
        urlField: {
          label: 'Submit A Paper For Indexing',
          placeholder: 'Eg: http://www.my-papers-are-here.invalid/paper-title.pdf',
        },
        submitButton: 'Add',
      },
      listItem: {
        label: 'Index Request',
      },
    },
    successMessage: 'Changes received.',
    reason: {
      instructions: 'TO ADD THIS PAPER TO YOUR AUTHOR PAGE, PLEASE SPECIFY YOUR REQUEST:',
      backLabel: 'BACK',
      choices: {
        notListed: {
          reasonLabel: 'My name is missing from the author list',
          instructions: 'SELECT WHERE YOUR NAME SHOULD BE ADDED',
        },
        wrongAuthor: {
          reasonLabel: 'Replace an existing author with me',
          instructions: 'SELECT THE AUTHOR TO REPLACE',
        },
        wrongName: {
          reasonLabel: 'The author list is correct, but the paper is missing from my author page',
          instructions: 'SELECT YOUR NAME IN THE AUTHOR LIST',
        },
      },
    },
  },

  authorRemovePapers: {
    publisher: {
      onlyVenue: 'Published in $', // $1 = venue
      onlyYear: 'Published $1', // $1 = year published
      bothYearAndVenue: 'Published $1 in $2', // $1 = year published, $2 = venue
    },
    removeAllLabel: 'Mark All Papers For Removal',
    numResults: {
      singular: '$1 Attributed Paper', // $1 = number of papers
      plural: '$1 Attributed Papers', // $1 = number of papers
    },
    filterLabel: 'Search for papers by Semantic Scholar URL, title, publisher, or author name',
    blankFilterLabel: 'No Papers Matching "$1"', // $1 = query search
    blankPaperLabel: 'No Papers To Remove',
    clearFilter: 'CLEAR FILTER',
    undoLabel: 'UNDO',
    error: {
      apiQueryFailed: 'An error occurred while submitting this page. Please try again.',
      apiSubmitFailed: 'An error occurred while saving your changes. Please try resubmitting.',
      correctionPending: 'Change is still being processed.',
    },
    successMessage: 'Changes received.',
    resetStagedLabel: 'Reset',
    stagedRemoveCorrections: {
      singular: 'Removing <strong>$1 Paper</strong> From Your Author Page', // $1 = number of papers
      plural: 'Removing <strong>$1 Papers</strong> From Your Author Page', // $1 = number of papers
    },
    removeAriaLabel: 'Remove this paper',
  },

  correctionSummary: {
    statusLabel: {
      Submitted: 'PROCESSING',
      Cancelled: 'CANCELLED',
      Processing: 'PROCESSING',
      Failed: 'FAILED',
      Succeeded: 'COMPLETE',
    },
    authorCorrections: {
      headerText: 'Display Information Changes',
      nameFieldLabel: 'NAME',
      affiliationsFieldLabel: 'AFFILIATIONS',
      homepageFieldLabel: 'HOMEPAGE',
      orcidFieldLabel: 'ORCID ID',
    },
    addCorrections: {
      headerText: {
        singular: 'Adding $1 Paper To Your Author Page', // $1 = number of papers
        plural: 'Adding $1 Papers To Your Author Page', // $1 = number of papers
      },
    },
    removeCorrections: {
      headerText: {
        singular: 'Removing $1 Paper From Your Author Page', // $1 = number of papers
        plural: 'Removing $1 Papers From Your Author Page', // $1 = number of papers
      },
    },
    viewerClaimedLabel: 'YOU',
    noCorrections: {
      label: 'No Changes Made To Your Author Page',
      authorPageButton: 'VIEW YOUR AUTHOR PAGE',
    },
  },

  admin: {
    pageTitle: 'Admin Tools | Semantic Scholar',
    headerLabel: 'Admin Tools',
    nav: {
      experimentsLabel: 'Experiments',
      enrollmentsLabel: 'Enrollments',
      featureFlagsLabel: 'Feature Flags',
    },
    experiments: {
      auditPage: {
        noExperimentKeyLabel: 'No experiment key found',
        loadingMessageLabel: 'Loading...',
        dateLabel: 'Date',
        statusLabel: 'Status',
        trafficRatioLabel: 'Traffic Ratio',
        variationRatioLabel: 'Variation Ratios',
      },
      localLabel: 'Local',
      devLabel: 'Dev',
      prodLabel: 'Prod',
      descNotSetLabel: 'No description',
      variationsLabel: 'Variations',
      terminateButtonLabel: 'Terminate',
      pathLabel: 'Path',
      manuallyExposedLabel: 'Manually Exposed',
      allocationTypeLabel: 'Allocation Type',
      statusLabel: 'Status',
      defaultVariationLabel: 'Terminated with variation',
      auditLabel: 'Audit',
      warning: 'WARNING - HARDCODED DEFAULT IS STILL "$1"', // $1 = default variation
      warningMessage:
        'Please remove the experiment or update the defaultVariation in code so tests/local reflect reality.',
    },
    adminLoginAsPage: {
      loadingMessageLabel: 'Loading... ',
      loginAsLabel: 'Login As',
      currentlySignedInAsLabel: 'Currently signed in as $1 [userId=$2]', // $1 = viewing user's name, $2 = viewing user's id
      subjectUserErrorLabel:
        'Somehow, you clicked the "Login As" button before it was visible. Are you a magician?',
      addAlertLabel:
        "Add a reason, so people don't bug you when they get an email saying you're up to no good!",
      stepOneLabel: 'Step 1. – Find the user',
      stepTwoLabel: 'Step 2. – Verify User Info',
      stepThreeLabel: 'Step 3. – Login As User',
      warningLabel:
        'WARNING: This will email s2-online@ for awareness. Make sure you let someone know what you are doing.',
      greatPowerLabel: "With great power, come's great responsibility.",
      uncleBenLabel: '– Uncle Ben',
      failedToFindUserErrorLabel: 'Failed to find user with ID $1', // $1 = user id
      subjectUserIdErrorLabel: 'Value $1 is not a number, enter a valid number', // $1 = entered value
      unknownErrorLabel: 'An unknown error occurred. Check console for details.',
      lookUpSubjectLabel: 'Look Up Subject',
      subjectUserIdLabel: "Subject's UserID",
      reasonLabel: 'Reason',
      loginAsUserLabel: 'Login As $1', // $1 = user's name
    },
    featureFlags: {
      descNotSetLabel: 'No description',
      description:
        'Feature flags are used to control the rollout of features to users. The Duration type of a feature flag indicates how long it should live for. "Temporary" should be used during development and should be cleaned up after a rollout, while "Permanent" are not removed and are used to turn off features (like those with third-party dependencies).',
      durationLabel: 'Duration',
      fallbackLabel: 'Fallback',
      fallbackState: {
        true: 'Enabled',
        false: 'Disabled',
      },
      ratioLabel: 'Enable for',
    },
    enrollments: {
      inCodeHeader: 'Enrollments in Code',
      inDBHeader: 'Enrollments only in Database',
      enrollButtonLabel: 'Enroll',
      enrollLinkLabel: 'Enroll Link',
      withdrawButtonLabel: 'Withdraw',
      withdrawLinkLabel: 'Withdraw Link',
      archiveButtonLabel: 'Archive',
      archiveDescription: 'Withdraws all users from enrollment',
      userCountLabel: {
        singular: '$1 user', // $1 = number of users
        plural: '$1 users', // $1 = number of users
      },
    },
    updateButton: 'Update',
    errors: {
      alreadyRunning: 'Cannot modify to lower traffic ratio because experiment is already running.',
      finishedExperiment: 'Cannot modify finished experiment.',
      apiError: 'Encountered failure while updating status.',
      validVariationRatios: 'Variation ratios must add up to 100%',
    },
    terminateModal: {
      fallbackVariationText: 'Which variation do you want to fallback to?',
      terminateExperimentHeader: 'Terminate Experiment',
      terminateError: 'Fallback variation is required to terminate experiment.',
      terminateButtonLabel: 'Terminate',
      cancelButtonLabel: 'Cancel',
    },
  },

  moderation: {
    pageTitle: 'Moderation Tools | Semantic Scholar',
    nav: {
      authorClaimLabel: 'Author Claims',
      authorshipLabel: 'Authorship Corrections',
      correctionsToolLabel: 'Author Corrections Tool',
    },
  },

  modal: {
    cite: {
      modalTitle: 'Cite Paper',
      export: 'Export',
      copyButtonText: 'Copy',
      copyButtonTextCopied: 'Copied',
      copiedCitationAnnouncement: 'Copied to clipboard',
    },
    export: {
      copyTitle: 'Copy to Clipboard',
      downloadTitle: 'Download',
      citationStyleLabel: {
        BibTex: 'BibTeX',
        EndNote: 'EndNote',
        MLA: 'MLA',
        APA: 'APA',
        Chicago: 'Chicago',
      },
      exportCountLabel: {
        singular: 'Export 1 Record',
        plural: 'Export $1 Records', // $1 = number of papers to export
      },
      exportErrorMessage: {
        singular: 'Unable to export 1 selected paper',
        plural: 'Unable to export $1 selected papers', // $1 = number of failed papers
      },
    },
    problems: {
      submitButton: 'Submit',
      text: 'Please describe any inaccuracies, formatting issues or other problems present on the page.',
      title: 'Help us improve our author profile pages:',
    },
  },

  correctionsCurrentPapers: {
    headerText: {
      singular: '$1 Current Paper', // $1 = number of papers
      plural: '$1 Current Papers', // $1 = number of papers
    },
  },

  paper: {
    badge: {
      clinicalTrial: {
        title: 'Clinical Trial',
        tooltip: 'This paper describes a government registered clinical trial.',
      },
      cord19: {
        title: 'CORD-19',
        tooltip: 'This paper is included in the CORD-19 (Coronavirus Open Research) dataset.',
      },
      openAccess: {
        title: 'Open Access',
        tooltip: 'This paper has an available PDF.',
        ariaLabel: 'This paper has an available PDF.',
      },
      prePrint: {
        title: 'Pre-Print',
        tooltip: 'This paper was published in a preprint archive.',
      },
      unpaywall: {
        title: 'Unpaywall',
        tooltip: 'This paper has an available open access PDF.',
        ariaLabel: 'This paper has an available open access PDF',
      },
    },
    landingPage: {
      title: 'Papers | Semantic Scholar',
    },
    link: {
      acl: 'View on ACL',
      acm: 'View on ACM',
      anansi: 'View Paper',
      arxiv: 'View PDF on arXiv',
      dblp: 'View Paper',
      defaultPublisher: 'Publisher',
      doi: 'View via Publisher',
      educause: 'View on Educause',
      getftr: 'Access PDF via Institution',
      ieee: 'View on IEEE',
      institutional: 'Check Your Institution',
      libkey: 'Access PDF via LibKey',
      medline: 'View on PubMed',
      openaccess: 'View Paper',
      publisher: 'View on $1', // $1 = publisher name
      s2: 'View PDF',
      ssrn: 'View on SSRN',
      unsupported: 'Access Paper',
      wiley: 'View via Publisher',
    },
    linkForPaperRow: {
      acl: 'ACL',
      acm: 'ACM',
      anansi: 'View Paper',
      arxiv: 'arXiv',
      dblp: 'View Paper',
      defaultPublisher: 'Publisher',
      doi: 'Publisher',
      educause: 'Educause',
      getftr: 'PDF via Institution',
      ieee: 'IEEE',
      institutional: 'Check Your Institution',
      libkey: 'LibKey',
      medline: 'PubMed',
      openaccess: 'View Paper',
      publisher: '$1', // $1 = publisher name
      s2: 'PDF',
      ssrn: 'SSRN',
      unsupported: 'Access Paper',
      wiley: 'Publisher',
    },
    alternate: 'Alternate Sources',
    action: {
      alert: 'Alert',
      addToLibrary: 'Add to Library',
      cite: 'Cite',
      citeAriaLabel: 'Cite this paper',
      disableAlert: 'Disable Alert',
      feed: 'Feed',
      followCitations: 'Follow Citations',
      goToLibrary: 'In Library',
      goToLibraryMobile: 'Library',
      inLibraryAriaLabel: 'In Library',
      inLibraryResponsiveText: 'Saved',
      save: 'Save to Library',
      saveToLibraryAriaLabel: 'Save to Library',
      saveShort: 'Save',
      slides: 'View Slides',
      notRelevantAnnotation: 'Not Relevant',
    },
    meta: {
      corpusId: 'Corpus ID: $1', // $1 = corpus ID,
      doi: 'DOI:',
      pubDate: 'First Publication:',
      published: 'Published',
    },
    noLinkLabel: 'No Paper Link Available',
    openAccess: 'Open Access',
    title: {
      missingInCorpus: 'This paper is not yet in our corpus.',
    },
    institutionalAccessBanner: {
      new: 'NEW',
      institutionalAccess: 'Institutional Access',
    },
    authorToggle: {
      author: {
        singular: '+1 author',
        plural: '+$1 authors',
      },
      less: 'Less',
    },
  },

  paperDetail: {
    abstract: {
      highlight: {
        paragraphLabel: 'Related Context from the Paper',
        paragraphEllipsis: '...',
        pdfLinkLabel: 'View Page In PDF',
        highlightToggleLabel: 'Highlight Information',
        methodsToggleLabel: 'Methods',
        resultsToggleLabel: 'Results',
      },
    },
    authorClaim: {
      headline: 'Is this your paper? Select your name to claim your author page:',
    },
    aside: {
      citationStatsEnum: {
        highlyInfluenced: '<strong>$1</strong> Highly Influenced Citations',
      },
    },
    referenceBadge: 'Highly Influential',
    citationBadge: 'Highly Influenced',
    notInCorpus: 'Sorry, this paper is not in our corpus.',
    sectionTitles: {
      allTopics: 'All Topics',
      citingPapers: 'Citations',
      connections: {
        singular: 'Connection',
        plural: 'Connections',
      },
      paperLabs: 'Scholar Labs',
      paperQA: 'Ask This Paper',
      referencedPapers: 'References',
      similarPapers: 'Related Papers',
      topics: {
        singular: 'Topic',
        plural: 'Topics',
        aiGenerated: {
          buttonLabel: 'AI-Generated',
          popoverTitle: 'AI-Generated',
          popoverBody:
            'These are topics this paper is cited for, or topics that are mentioned in the paper. Topics are extracted from the title and abstract of a paper.',
        },
      },
    },
    sectionSubtitles: {
      extractedContent: '$1 from this paper', // $1 = list of content types extracted from this paper
      figures: 'Figures',
      tables: 'Tables',
      results: 'Results',
      topics: 'Topics',
    },
    tabLabels: {
      abstract: 'Abstract',
      citingPapers: {
        singular: '1 Citation',
        plural: '$1 Citations',
      },
      extractedContent: 'From This Paper',
      paperLabs: 'Scholar Labs',
      paperQA: 'Ask This Paper',
      referencedPapers: {
        singular: '1 Reference',
        plural: '$1 References',
      },
      similarPapers: 'Related Papers',
    },
    matched: {
      paginationButtonText: 'SHOW MORE',
    },
    paperLabs: {
      disclaimerHtml:
        'Something something generic disclaimer something. <a style="text-decoration: underline" href="/faq#ai-features">Learn more</a> about Labs or something.',
      feedback: 'Feedback?',
      contactUsLink: 'Contact Us',
    },
    paperQA: {
      disclaimerHtml:
        'Our system tries to constrain to information found in this paper. Results quality may vary. <a style="text-decoration: underline" href="/faq#ai-features">Learn more</a> about how we generate these answers.',
      feedback: 'Feedback?',
      contactUsLink: 'Contact Us',
    },
    scorecard: {
      citedBy: {
        headline: {
          singular: '1 Citation',
          plural: '$1 Citations',
        },
      },
      highlyInfluential: {
        description: ' Most Influenced Papers',
        title: 'Highly Influential Citations',
        viewAll: 'View All',
        faqAriaLabel: 'Learn more about highly influenced citations',
      },
      citationIntent: {
        methodology: ' Methods Citations',
        result: ' Results Citations',
        background: 'Background Citations',
      },
    },
    stats: {
      sectionTitle: 'Statistics',
      citations: {
        id: 'citations',
        display: 'Citations',
      },
    },
    figure: {
      // $1 = figure.displayName
      // $2 = paper.title.text
      pageTitle: '$1 from $2 | Semantic Scholar',
      fallbackPageTitle: 'Figure | Semantic Scholar',
    },
    figureShowMoreLabel: 'View All $1 Figures & Tables', // $1 = total number of figures for paper
    relatedPapers: {
      noPapers: 'Sorry, we did not find any related papers.',
      loading: 'Loading related papers…',
      publisher: {
        onlyVenue: 'Published in ',
        onlyYear: 'Published $1', // $1 = year published
        bothYearAndVenue: 'Published $1 in ', // $1 = year published
      },
      citation: {
        singular: ' Citation',
        plural: ' Citations',
      },
      highlyInfluenced: {
        singular: ' Highly Influenced Paper',
        plural: ' Highly Influenced Papers',
      },
      narrowViewToggle: {
        relatedPapers: 'Related Papers',
        ShowMoreToggleText: 'Show More',
        ShowLessToggleText: 'Show Less',
        showMoreAriaLabel: 'Show more related papers',
        showLessAriaLabel: 'Show less related papers',
      },
      papersShowing: 'Showing $1 through $2 of $3 Related Papers',
      prevPapers: 'Previous related papers',
      nextPapers: 'Next related papers',
    },
    pageTitle: '$1 | Semantic Scholar', // $1 = paper title,
    viewReaderButton: 'View With Semantic Reader',
    viewReaderButtonWithPDFPrefix: '[PDF] Semantic Reader',
    viewReaderButtonWithoutSemanticReaderSuffix: '[PDF]',
    readerButtonAriaLabel: 'Semantic Reader, an AI-powered PDF reader',
    viewReaderButtonTooltip: 'Semantic Reader, an AI-powered PDF reader',
    readerTooltip: 'An AI-powered scientific PDF reader',
    morePaperLinks: 'More paper links',
  },

  authorSuggestions: {
    pageTitleWithAuthor: 'Suggest Changes to $1 | Semantic Scholar', // $1 = author name
    pageTitleWithoutAuthor: 'Suggest Changes | Semantic Scholar',
  },

  feedback: {
    modalTitle: 'Contact Us',
    modalHeaderContent:
      'Please visit our <a href="/faq">FAQ</a> to find helpful information before submitting your question.',
    buttonText: 'Contact Us',
    sendingText: 'Sending...',
    submittingText: 'Submitting...',
    successText: 'Your feedback has been submitted.',
    successTitle: 'Thanks!',
    error: {
      clientError: 'An error occurred on in your browser while saving your feedback.',
      serverError: 'An error occurred on our server while saving your feedback.',
      unknownError: 'An unexpected error occurred while saving your feedback.',
      submissionError:
        'There was a problem submitting the form. If this issue persists, you can reach us via email at  <a href="mailto:feedback@semanticscholar.org">feedback@semanticscholar.org</a>.',
      serviceDownError:
        'We\'re currently experiencing problems with our feedback form. If this issue persists, you can reach us via email at <a href="mailto:feedback@semanticscholar.org">feedback@semanticscholar.org</a>.',
    },
    form: {
      name: 'Your name',
      email: 'Your email',
      subject: 'Subject',
      topic: 'Topic',
      feedback: 'Feedback',
      url: 'Page URL',
      noLoginEmail: 'No login email',
      loginDetails: 'Signed in as',
      topicDropdownLabel: 'Select A Topic',
      topicOptions: {
        // Display values adjusted according to https://github.com/allenai/scholar/issues/11760
        authorDisambiguation: 'Author Correction',
        dataQuality: 'Data Correction',
        featureRequest: 'Make A Suggestion',
        indexJournalRequest: 'Index a Journal',
        takedownRequest: 'Remove A Paper',
        tldr: 'TLDR',
        semanticReader: 'Semantic Reader',
        otherProblem: 'Other',
      },
      feedbackPlaceholderText: {
        takedownRequest:
          "Please include a URL to the paper on Semantic Scholar you'd like to have removed.",
      },
      error: {
        missingName: 'Please fill out your name.',
        missingEmail: 'Please fill out your email.',
        invalidEmail: "Please include an '@' in the email address.",
        missingSubject: 'Please fill out a subject.',
        missingTopic: 'Please select a topic.',
        missingFeedback: 'Please provide your feedback.',
      },
    },
  },

  figureDetail: {
    published: {
      bothYearAndVenue: 'Published in $1 $2', // $1 = venue, $2 = year published
    },
    closeButtonLabel: 'Close',
    closeModalAriaLabel: 'Close figure modal',
    continueReadingLabel: 'Continue Reading',
    expandAriaLabel: 'Expand figure caption',
    nextAriaLabel: 'Next Figure',
    prevAriaLabel: 'Previous Figure',
    title: 'Figures Carousel',
    truncateAriaLabel: 'Collapse figure caption',
  },

  home: {
    pageTitle: 'Semantic Scholar | AI-Powered Research Tool',
    metaDescription:
      'Semantic Scholar uses groundbreaking AI and engineering to understand the semantics of scientific literature to help Scholars discover relevant research.',
    headline: 'Semantic Scholar',
    subHeadline: 'A free, AI-powered research tool for scientific literature',
    share: {
      title: 'Discover key publications, citations and results using Semantic Scholar:',
    },
    ads: {
      publicApi: {
        headline: 'New & Improved API for Developers',
        content:
          'Our API now includes paper search, better documentation, and increased stability. Join hundreds of other developers and start building your scholarly app today.',
        cta: 'Get Started',
      },
      semanticReader: {
        headline: 'Introducing Semantic Reader in Beta',
        content:
          'Semantic Reader is an augmented reader with the potential to revolutionize scientific reading by making it more accessible and richly contextual. Try it for select papers.',
        cta: 'Learn More',
      },
    },
    subscribe: {
      ariaTitle: 'Newsletter Subscribe',
      placeholder: 'Your E-mail Address',
      subscribeBlurb: 'Stay Connected With Semantic Scholar',
      signUp: 'Sign Up',
      subscribing: 'Subscribing...',
      thankYou: 'Thank You',
      thankYouBlurb: 'Watch your inbox for exciting changes coming to Semantic Scholar!',
    },
  },

  entity: {
    landingPage: {
      title: 'Topics | Semantic Scholar',
    },
    pageTitle: '$1 | Semantic Scholar', // $1 = entity name
    relatedTopics: {
      toggleExpand: 'VIEW ALL',
      toggleCollapse: 'VIEW FEWER',
    },
    description: {
      toggleExpand: 'Expand',
      toggleCollapse: 'Collapse',
    },
  },

  alerts: {
    pageTitle: 'My Alerts | Semantic Scholar',
    login: 'Please sign in to create an alert',
    pageHeadline: 'My Alerts',
    linkToAlerts: 'All Alerts',
    linkToEmail: 'Email Address',
    createAlerts: {
      SEARCH_RESULTS: 'Create Alert',
      AUTHOR: 'Follow Author',
      AUTHOR_PAPER: 'Create Alert for New Author Papers',
      AUTHOR_CITATION: 'Create Alert for New Author Citations',
      PAPER_CITATION: 'Create Alert',
      ENTITY_PAPER: 'Create Alert',
      paperAlertAriaLabel: 'Create Alert for this paper',
    },
    disableAlerts: {
      SEARCH_RESULTS: 'Disable Alert',
      AUTHOR_PAPER: 'Disable Alert for New Author Papers',
      AUTHOR_CITATION: 'Disable Alert for New Author Citations',
      PAPER_CITATION: 'Disable Alert',
      ENTITY_PAPER: 'Disable Alert',
      paperAlertAriaLabel: 'Turn off email alert for this paper',
    },
    activateAlerts: {
      SEARCH_RESULTS: 'Activate Alert',
      AUTHOR_PAPER: 'Activate Alert for New Author Papers',
      AUTHOR_CITATION: 'Activate Alert for New Author Citations',
      PAPER_CITATION: 'Activate Alert',
      ENTITY_PAPER: 'Activate Alert',
      paperAlertAriaLabel: 'Turn on email alert for this paper',
    },
    queryType: {
      SEARCH_RESULTS: {
        title: 'New Search Results',
      },
      AUTHOR_PAPER: {
        title: 'New Papers From Authors',
      },
      AUTHOR_CITATION: {
        title: 'New Author Citations',
      },
      PAPER_CITATION: {
        title: 'New Citations For Papers',
      },
      ENTITY_PAPER: {
        title: 'New Papers From Topics',
      },
      LIBRARY_FOLDER: {
        title: 'New Research Feed Papers',
      },
    },
    empty: {
      title: 'No alerts',
      description:
        'Create alerts for searches, authors, papers, and topics to be notified when we add new content.',
    },
    create: {
      SEARCH_RESULTS: {
        createdTitle: 'Create Search Alert',
        exists: 'You already have an alert for when we find new search results for:',
        description: 'Receive an alert whenever we find new papers in the first 50 results for:',
      },
      AUTHOR_PAPER: {
        createdTitle: 'Author Paper Alert Created',
        title: 'Create Author Alert',
        exists: `You already have an alert for when this author publishes a new paper: $1`, // $1 = paper title
        description: 'Receive an alert whenever this author publishes a new paper:',
      },
      AUTHOR_CITATION: {
        createdTitle: 'Author Citation Alert Created',
        title: 'Create Author Citation Alert',
        exists: `You already have an alert for when this author is cited: $1`, // $1 = author name
        description: 'Receive an alert whenever this author is cited:',
      },
      PAPER_CITATION: {
        createdTitle: 'Paper Alert Created',
        title: 'Create Paper Alert',
        exists: `You already have an alert for when this paper receives a new citation: $1`, // $1 = paper title
        description: 'Receive an alert whenever this paper receives a new citation:',
      },
      ENTITY_PAPER: {
        createdTitle: 'Topic Alert Created',
        title: 'Create Topic Alert',
        exists: `You already have an alert for when new papers mention this topic: $1`, //$1 = topic name
        description: 'Receive an alert whenever new papers mention this topic:',
      },
      LIBRARY_FOLDER: {
        createdTitle: 'Folder Alert Created',
        title: 'Create Folder Alert',
        exists: `You already have an alert for when new papers are recommended based on this folder: $1`, //$1 = folder name
        description: 'Receive an alert whenever new papers are recommended based on this folder:',
      },
      form: {
        resendVerificationLabel:
          'It looks like we do not have a verified email address for you. You can still create this alert, but no updates will be sent until your email address has been verified.',
        resendVerificationText: 'Resend verification email?',
        buttonText: 'Create Alert',
        cancelButtonText: 'Cancel',
        deleteAlertHeader: 'Delete Alert?',
        confirmDeleteText: 'Yes, Delete Alert',
      },
      message: {
        success: {
          header: 'Alert Created',
          body: `$1 has been created as a new alert.`, // $1 = Paper title/topic/author name for alert
          citationAlertBody: `You will receive alerts to $1 when <b>$2</b> has new citations.`, // $1 = email $2 = title/author
          authorPaperAlertBody: `You will receive alerts to $1 when <b>$2</b> publishes a new paper.`, // $1 = email $2 = author
          entityAlertBody: `You will receive alerts to $1 when new papers mention this topic: <b>$2</b>.`, // $1 = email $2 = entity
          feedPaperAlertBody: `You will receive alerts to $1 when new papers mention this research feed: <b>$2</b>.`, // $1 = email $2 = entity
          alertsButton: 'Manage My Alerts',
        },
        error: {
          header: 'Error',
          body: 'There was an error saving to your alerts. Try adding this alert again.',
        },
        limitError: {
          header: 'Limit Reached',
          body: 'You have reached the limit on alerts. To create this alert, you will need to delete others.',
        },
        duplicate: {
          header: 'Duplicate Alert',
        },
      },
    },
    delete: {
      SEARCH_RESULTS:
        'Deleting this alert means you will no longer receive notifications when we discover new search results for:',
      AUTHOR_PAPER:
        'Deleting this alert means you will no longer receive notifications when we discover new papers that include this author:',
      AUTHOR_CITATION:
        'Deleting this alert means you will no longer receive notifications when we discover new citations for:',
      PAPER_CITATION:
        'Deleting this alert means you will no longer receive notifications when we discover new citations for this paper:',
      ENTITY_PAPER:
        'Deleting this alert means you will no longer receive notifications when we discover new papers that mention this topic:',
      LIBRARY_FOLDER:
        'Deleting this alert means you will no longer receive notifications when we discover new papers that match your research feed:',
      success: 'Your alert has been deleted.',
      error: 'Error: Could not delete alert.',
      noAuth: 'You must be logged in to delete an alert.',
      removeLabel: 'Remove',
    },
    email: {
      updateSuccess: 'Successfully updated email address for $1',
      verifySuccess: 'Verification email sent to $1',
      addEmail: 'Add Email Address',
      resendEmail: 'Resend Verification Email',
      updateEmail: 'Update Email Address',
      verification: {
        verified: 'Verified',
        unverified: 'Not Verified',
      },
      placeholderEmail: 'Enter your email address here',
      headline: 'Alert Email Address',
    },
    unsubscribe: {
      pageTitle: 'Unsubscribing from Alert | Semantic Scholar',
      success: 'You have been successfully unsubscribed from your alert(s).',
      failure: {
        authedHeader: 'We were unable to unsubscribe you from your alert(s).',
        authedSubheader: 'Please try managing them from your alerts page.',
        unauthedHeader: 'We were unable to unsubscribe you from your alert(s).',
        unauthedSubheader: 'Please login to manage your alerts directly.',
      },
    },
    verification: {
      pageTitle: 'Verifying Email | Semantic Scholar',
      verifying: 'Verifying...',
      failed: {
        genericErrorMessage: "Sorry, we couldn't verify your email",
        alreadyVerified: 'Your email address has already been verified.',
        tokenExpired:
          'Your verification token has expired. Please log-in to resend a verification email.',
        default:
          'We were unable to verify your email address at this time. Please try again later.',
      },
      success: {
        genericAlertMessage: 'Email Address Verified!',
        noAlerts: 'You will now receive alerts for your subscribed queries.',
        oneAlert: 'You will now receive alerts for "$1".',
        multipleAlerts: 'You will now receive alerts for "$1" and $2 other queries.',
      },
    },
    frequency: {
      title: 'Frequency',
      subtitle: 'At most, how often would you like to receive emails?',
      frequencies: {
        daily: 'Once a Day',
        weekly: 'Once a Week',
      },
    },
  },
  aiGeneratedTag: {
    label: 'AI Generated',
  },
  aiPoweredTag: {
    label: 'AI-Powered',
  },
  betaTag: {
    label: 'BETA',
  },
  moreAuthors: {
    expandAriaLabel: 'Show $1 more authors', // $1 = number of extra authors
  },
  library: {
    unauthorizedPageTitle: 'Unauthorized | Semantic Scholar',
    pageTitle: 'Library | Semantic Scholar',
    pageTitleWithFolder: '$1 Library Papers | Semantic Scholar', // $1 folder title
    headline: 'Library',
    loadingIndicator: 'Loading',
    missingPaperHeader: 'This paper has been removed from Semantic Scholar',
    missingPaperBody:
      'This can happen when Semantic Scholar detects spam or receives a removal request.',
    annotationButtonLabels: {
      More: 'More Like This',
      Less: 'Less Like This',
    },
    nav: {
      allPapersLinkLabel: 'All Papers',
      unsortedPapersLinkLabel: 'Unsorted Papers',
      hiddenPapersLinkLabel: 'Hidden Papers',
      createFolderLinkLabel: 'Add New Folder',
      editTooltip: 'Edit Folder',
      addFolderButtonLabel: 'Add New Folder',
      editFolderAriaLabel: 'Edit Folder',
      close: 'Close',
    },
    addFolderForm: {
      folderNameLabel: 'New Folder Name',
      feedToggleLabel: 'Get Research Feed',
      submitButtonLabel: 'Add Folder',
      closeButtonLabel: 'Close',
      errorMessage: 'Failed to create folder. Please try again.',
      folderExistsErrorMessage:
        'A folder with that name already exists. Please enter a new folder name.',
      missingNameErrorMessage: 'Folder name is required',
    },
    blankState: {
      noFolders: 'Nothing Here Yet - Get Started by Creating Your First Folder',
      noFoldersMobile: 'Nothing Here Yet',
      noPapers: 'No Papers',
      noPapersInFolder: 'No Papers In This Folder',
      noPaperFilterResults: 'Sorry, there are no results for "$1"', // $1 = query
      noPapersUnsorted: 'No Unsorted Papers',
      newFolderNamePlaceholder: 'Folder Name',
      missingNameErrorMessage: 'Folder name is required',
      errorMessage: 'Failed to create folder. Please try again.',
      submitButtonLabel: 'Add Folder',
      gettingStartedMessage: 'Get Started by Creating Your First Folder',
    },
    shared: {
      headline: 'Shared Library Folder',
      createdBy: 'Created by ',
      shareOptionsLabel: 'Share',
      shareLabel: 'Share',
      copyLink: 'Copy a shareable link to your clipboard',
      copiedLink: 'Link copied',
      makePublicHeader: 'Make Folder Public?',
      makePublicDescription:
        "You must make this folder public in order to share it. Change this folder's visibility anytime using the settings panel.",
      makePublicButton: 'Share and Copy Link',
      cancel: 'Cancel',
      changePrivacySuccess:
        'Your folder is now public. The link to share it has been copied to your clipboard.',
      errorTitle: 'Error',
      updatePrivacyMessage: 'Unable to update folder privacy, please try again later.',
    },
    unauthorized: {
      headline: 'Shared List',
      mainText: 'This folder is not public.',
      createLibrary: 'Create your own library collection.',
      login: 'Log in or sign up to get started.',
      signInButton: 'Sign In',
      createAccountButton: 'Create Free Account',
    },
    header: {
      settings: 'Settings',
    },
    recsLabel: {
      recommendationsToggleLabel: 'Research Feed',
      reconmendationsToggleAriaLabel: 'Get Paper Recommendations Based on This Folder',
      recommendationToggleErrorMessage:
        'An error occurred while saving your Research Feed preference. Please try again.',
      recsExplanationLabel: "What's This",
      recommendationToggleErrorTitle: 'Error',
    },
    footer: {
      paperCountLabel: {
        singular: '$1 Paper', // $1 = number of papers in results
        plural: '$1 Papers', // $1 = number of papers in results
      },
    },
    folderName: {
      recommendationsEnabledTooltip: 'Recommendations On',
      recommendationsDisabledTooltip: 'Recommendations Off',
      publicFolder: 'Shared Publicly',
    },
    recommendationsExplanation: {
      headline: 'Get $1 AI Powered Recommendations', // $1 = <Icon/>
      description:
        'Your Research Feeds are now based on the papers in your Library. You will receive daily paper recommendations based on the papers saved in your folders.',
    },
    allEntries: {
      pageTitle: 'All Library Papers | Semantic Scholar',
      headline: 'All Papers',
    },
    unsortedEntries: {
      pageTitle: 'Unsorted Library Papers | Semantic Scholar',
      headline: 'Unsorted Papers',
    },
    description: {
      placeholder: 'Say something about this collection of papers...',
      save: 'Save',
      errorTitle: 'Error',
      updateDescriptionMessage: 'Unable to update folder description, please try again later.',
      charLimitLabel: ' characters',
    },
    libraryFolderSettingsShelf: {
      headerText: 'Folder Settings',
      copyLink: 'Copy Link',
      copiedLink: 'Copied!',
      folderNameLabel: 'Folder Name',
      folderDescriptionLabel: 'Folder Description',
      folderDescriptionPlaceholder: 'Say something about this paper list...',
      folderSharingLabel: 'Folder Sharing',
      saveButtonLabel: 'Save & Close',
      deleteButtonLabel: 'Delete Folder',
      folderNotFoundError: 'Error finding folder to update, please try again later.',
      updateErrorMessage: 'Unable to update folder settings, please try again later.',
      deleteErrorMessage: 'Unable to delete folder, please try again later.',
      confirmDeleteHeaderText: 'Confirm Delete',
      confirmDeleteDialog:
        'Deleting this folder will remove it from your library. The papers within the folder will remain in your library.',
      confirmDeleteButtonLabel: 'Yes, Delete',
      cancelDeleteButtonLabel: 'Cancel',
      recommendationText: 'Get Paper Recommendations Based on This Folder',
      alertText: 'Get Alerts for New Recommendations',
      researchFeedSetting: 'Research Feed Settings',
      notRelevantPapers: 'View Papers Marked as “Not Relevant”',
      closeShelfAriaLabel: 'Close library folder settings',
      editFolderName: 'Edit folder name',
      optionalLabel: 'Optional',
      privateFolderDescription: 'This folder is only visible to you',
      publicFolderDescription: 'Anyone with the link can view',
    },
    notRelevantModal: {
      blankStateHeader: 'No Papers Marked “Not Relevant Yet',
      blankStateDescription:
        'Marking at least three papers as “not relevant” will improve the quality of your recommendations.',
      header: 'Papers Marked As Not Relevant For $1',
      remove: 'Remove',
    },
    organizeLabel: 'Organize',
    singleFolderOrganizeLabel: '$1', // $1 folder name
    multiFolderOrganizeLabel: '$1 +$2', // $1 folder name, $2 number of additional folders
    moreFoldersText: {
      singular: ' and $1 other folder.',
      plural: ' and $1 other folders.',
    },
    organizePapersShelf: {
      headerText: 'Organize Paper',
      subHeadText: 'Choose Folders',
      filterPlaceholderText: 'Filter Folders',
      addNewFolderLabel: 'Add New Folder',
      researchFeedLabel: 'Get Research Feed',
      addFolderButtonLabel: 'Add Folder',
      cancelButtonLabel: 'Cancel',
      saveAndCloseButtonLabel: 'Save & Close',
      removeFromLibraryButtonLabel: 'Remove Paper From Library',
      errorMessage: 'Unable to move paper. Please try again later.',
      noFolderFound: 'No "$1" folder found',
      closeShelfAriaLabel: 'Close organize paper shelf',
    },
    recommendationsFooter: {
      showMoreButtonLabel: 'View Older Recommendations',
    },
    recommendationsList: {
      folderHeading: 'New Recommendations for ',
      folderShowMore: 'More Recommendations for $1', // $1 = name of folder
      noFoldersSelected: 'Not Viewing Any Feeds',
      noPapersCallToAction:
        'Saving additional papers helps us better understand your research area and increases the quality and likelihood of recommendations.',
      noPapersForFilter: 'No Recommendations',
      today: "Today's Recommendations",
      yesterday: "Yesterday's Recommendations",
      recommendationsFor: 'Recommendations for',
    },
    copyFolderButton: {
      title: 'Your Library',
      header: 'Save This Folder',
      description: 'Save a copy of this folder to your library.',
      buttonLabel: 'Copy Folder',
      mobileButtonLabel: 'Copy',
      errorText: 'Folder could not be copied. Please try again later.',
      copyingFolderLabel: 'Copying Folder...',
      copyingFolderDescription: 'This will take just a moment.',
      copiedFolderLabel: 'Now in Your Library',
      viewFolderLinkText: 'View Folder',
    },
    removeFromLibrary: {
      buttonLabel: 'Remove',
      successHeader: 'Removed From Library',
      undoLabel: 'Undo',
      successSubHeaderOneFolder: 'This paper was in: $1 Folder.', // $1 = name of folder
      successSubHeaderTwoFolders: 'This paper was in: $1 Folder and $2 Folder.', // $1, $2 = name of folders
      successSubHeaderThreeFolders: 'This paper was in: $1 Folder, $2 Folder , +1 more folder.', // $1, $2 = name of folders
      successSubHeaderMoreFolders: 'This paper was in: $1 Folder, $2 Folder , +$3 more folders.', // $1, $2 = name of folders, $3 = count of rest of folders
    },
    saveToLibraryShelf: {
      headerText: 'Saved To Library',
      subHeadText: 'Choose Folders',
      filterPlaceholderText: 'Filter Folders',
      addFolderLabel: 'Add New Folder',
      saveButtonLabel: 'Save & Close',
      errorMessage: 'Unable to save paper, please try again later.',
      folderLinkLabel: 'View',
      viewLibraryButtonLabel: 'View Library',
      noFolderFound: 'No "$1" folder found',
      checkboxAriaLabel: 'Save paper to $1',
      closeShelfAriaLabel: 'Close save to library shelf',
    },
    searchSortBar: {
      placeHolderText: 'Search Papers',
    },
    select: {
      ALL: 'All',
      NONE: 'None',
    },
    sort: {
      alphabetical: 'Alphabetical',
      dateAdded: 'Date Added To Library',
      recency: 'Recency',
      relevance: 'Relevance',
    },
    message: {
      success: {
        header: 'Saved',
        bodyMobile:
          'This paper has been saved to your library. You can view your library by signing in from a desktop computer.',
      },
      duplicate: {
        header: 'Duplicate',
        body: '$1 is already in your library.', // $1 is the title of the paper.
      },
      duplicateTag: {
        header: 'Duplicate',
        body: '"$1" is already in your tag list.',
      },
      error: {
        header: 'Error',
        body: 'There was an error saving to your library. Try adding this paper again.',
      },
      errorCreateTag: {
        header: 'Error',
        body: 'There was an error creating your tag. Try adding this tag again.',
      },
      errorEditTag: {
        header: 'Error',
        body: 'There was an error editing your tags. Try saving your tags again.',
      },
      errorDeleteTag: {
        header: 'Error',
        body: 'There was an error removing your tag. Try removing this tag again.',
      },
      errorRenameTag: {
        header: 'Error',
        body: 'There was an error renaming your tag. Try renaming this tag again.',
      },
      deleteError: {
        header: 'Error',
        body: 'Sorry, we are unable to complete your request at this time. Please try again.',
      },
      deleteTag: {
        header: 'Remove "$1" Tag?',
        body: 'Removing this tag will delete all mention of it from your library. The papers tagged within will remain in your library.',
        confirm: 'Yes, Remove',
        cancel: 'No, Do Not Remove',
      },
      editTags: {
        header: 'Edit Tags',
        countSingle: 'paper',
        countPlural: 'papers',
        confirm: 'Save Tags',
        cancel: 'Cancel',
      },
    },
    promo: {
      publicFolders: 'Public Folders',
      publicFoldersDescription:
        'Make a folder public using the settings panel. Share the link with others, who can save a copy to their own libraries.',
    },
  },
  textTruncator: {
    default: {
      extendAriaLabel: 'Expand truncated text',
      extendLabel: 'Expand',
      truncateAriaLabel: 'Hide truncated text',
      truncateLabel: 'Collapse',
    },
  },
  tldr: {
    tldrLabel: 'TLDR',
    tooltip: {
      contactUsLink: 'contact us',
      description:
        "TLDR (short for Too Long, Didn't Read) is an automatically generated short summary of a paper.",
      feedback: 'If you have feedback on this experience, ',
      learnMoreLink: 'Learn more about TLDR',
    },
    abstractLabel: 'Abstract',
  },
  maintenance: {
    general:
      'Semantic Scholar is currently undergoing maintenance, some functionality may be limited.',
  },
  recommendations: {
    pageTitle: 'Research Feeds | Semantic Scholar',
    headline: 'Research Feeds',
    tagLine: 'State of the art AI-powered recommendations based on papers you have saved to your ', // intentional trailing space here
    tagLineLibraryLink: 'library',
    notRelevantPopup: {
      title: 'Paper Annotated',
      message: '"$1" was marked as not relevant to "$2"', // $1 = paper title, $2 = feed/folder title
      undoLabel: 'Undo',
    },
    settingsLabel: 'Settings',
    settingsShelf: {
      headerText: 'Research Feed Settings',
      folderHeader: 'Source Folders',
      folderDescription:
        'Research Feed recommendations are based on the folders in your library. You can choose to exclude any folder.',
      emailAlertsText: 'E-mail Alerts',
      emailAlertsDescription:
        'Manage whether or not you receive Research Feed recommendations to your e-mail.',
      managePreferencesLabel: 'Manage Preferences',
      manageAlert: 'Manage Alerts',
      closeButtonLabel: 'Close',
      closeShelfAriaLabel: 'Close research feed settings',
      viewLibraryLabel: 'View Library',
      errorMessage: 'Unable to update folder recommendation state, please try again later.',
    },
    recommendationsViewingFilterHeader: 'Viewing...',
    limitFeed: 'Only 10 feeds can be turned on at one time.',
    activeFeeds: 'active feeds',
    recommendationsViewingAriaLabel: 'Manage Feed Display',
  },
  research: {
    pageTitle: 'Research Dashboard | Semantic Scholar',
    headline: 'Research Dashboard',
    groupHeader: {
      authorCitations: {
        beforeName: {
          singular: 'New Citation For',
          plural: 'New Citations For',
        },
        afterName: {
          singular: "'s Paper",
          plural: "'s Papers",
        },
        separatorBetweenPapers: ', ',
        separatorBetweenLastPaper: ', and ',
        separatorBetweenTwoPapers: ' and ',
      },
      authorPapers: {
        singular: 'New Paper From',
        plural: 'New Papers From',
      },
      entityPapers: {
        singular: 'New Paper On',
        plural: 'New Papers On',
      },
      tagRecommendations: {
        singular: 'Latest Recommended Paper For',
        plural: 'Latest Recommended Papers For',
      },
      hiddenTagRecommendations: {
        singular: 'Hidden Recommendation For',
        plural: 'Hidden Recommendations For',
      },
      paperCitations: {
        singular: 'New Citation For',
        plural: 'New Citations For',
      },
      recentCitations: {
        multipleCitedPapers: {
          singular: 'Most Recent Citation For Your Papers',
          plural: 'Most Recent Citations For Your Papers',
        },
        singleCitedPaper: {
          singular: 'Most Recent Citation For Your Paper',
          plural: 'Most Recent Citations For Your Paper',
        },
      },
    },
    searchBarPopover: {
      fromSideBar: {
        mainText: 'Create a new research feed from any paper on Semantic Scholar.',
      },
      fromRatedPapersTab: {
        mainText: 'Search for additional papers to rate.',
        subText: 'Update this research feed’s definition by rating any paper on Semantic Scholar.',
      },
    },
    recommendations: {
      annotateErrorMessage: 'An error occurred when trying to save annotation. Try again later.',
      annotationCard: {
        cardLabel: `We'll show fewer papers like this`,
        improveRecsMessage:
          'To improve your recommendations, add at least 5 papers to your folder, and mark 3 papers as not relevant.',
        notRelevantPapers: 'View Papers Marked as “Not Relevant”',
        closeButtonLabel: 'Close',
        undoButtonLabel: 'Undo',
      },
    },
    homepage: {
      nav: {
        impactButtonLabel: 'Your Impact',
        recommendationsButtonLabel: 'Your Research Feeds',
        alertsButtonLabel: 'Your Alerts',
      },
      authorImpactCard: {
        listTitle: 'Your Impact',
        authorPageLink: 'View Your Author Page',
      },
      defaultFolderName: 'Your Library',
      latestAlertsCard: {
        listTitle: 'New Papers From Your Alerts',
      },
      latestRecsCard: {
        header: 'Recommended Papers From Your Research Feeds',
        recommendationsLinkLabel: 'View Your Research Feeds',
      },
      promptContainer: {
        closeButtonAriaLabel: 'Close',
      },
      noContentMessage:
        'There is no recommended research for you today. Saving papers to your library, and creating alerts will increase your chances for regular updates.',
    },
    library: {
      listTitle: 'Library',
    },
    onboardingHomepage: {
      createLibraryModal: {
        header:
          'Help us understand your research by saving papers to your library that have influenced you.',
        placeholder: 'Search for papers that are influenced by your research',
        mobilePlaceholder: 'Search for influential research',
        laterButton: 'Set Up Later',
        searchButton: 'Search',
        finishButton: 'Finish Set Up',
        footerText: 'Saved Papers',
        mobileFooterText: 'View Saved Papers',
        searchButtonAriaLabel: 'Search',
        removePaperAriaLabel: 'Remove Paper',
        addPaperAriaLabel: 'Add Paper',
        firstLibraryFolder: 'Influential Papers',
        errorMessage: 'An error occurred when trying to set up your library. Please try again.',
        papersSelectedScreenReaderLabel: '$1 of $2 papers selected', // $1 = number of papers selected, $2 = max selected papers
        mobileFooterSelectedPapersButton: {
          singular: '1 Paper',
          plural: '$1 Papers', // $1 = number of selected papers
        },
        blankState: {
          header: 'No Papers Found',
          noResultsText: 'No results found for ',
          clearSearchButtonText: 'Clear Search',
        },
      },
      dashboardHeadline: 'Stay Up To Date With Impactful Research',
      dashboardSubhead: 'Research Dashboard',
      researchDashboard: 'Research Dashboard',
      setUpRecommendationsButton: 'Set Up Recommendations',
      letsGetStartedButton: "Let's get started",
      libraryHeadline: 'Stay Organized, With Semantic Scholar',
      libraryDescription:
        'Save papers to your Library that have been instrumental to your work, to help us understand your research.',
      librarySubhead: 'Library',
      feedDescription:
        'Stay up to date with the most relevant papers via your AI-Powered Research Feeds',
      dashboardDescription:
        'Return often to see a snap shot of what’s new in your Research Dashboard',
      searchBarMessage:
        'Help us understand your research by searching for and saving papers to your Library that have influenced you.',
      feedHeadline: 'Stay Up To Date, With Semantic Scholar',
      feedSubhead: 'Research Feeds',
    },
  },
  export: {
    select: 'Select',
    selectOptions: {
      selectAll: 'All',
      selectNone: 'None',
    },
    papersSelected: {
      singular: '$1 Paper Selected',
      plural: '$1 Papers Selected',
    },
    exportButtonLabel: 'Export',
    goToLibrary: 'Go To Library ',
    bulkExportHeaderText: 'Bulk Export From Your Library',
    bulkExportDescriptionText: 'You can now export multiple records at once from your library.',
    exportLinkLabel: 'Go To Library',
    exportGetToKnowYourLibrary: 'Get To Know Your Library',
    copyButtonText: 'Copied',
    removeFromLibrary: 'Remove From Library',
    selectAllAriaLabel: 'All $1 papers',
    exportToggleAriaLabel: 'Open paper export selection options',
  },
  reader: {
    abstract: 'Abstract',
    citesee: {
      citedByLibraryDropdownLabel: 'This paper is cited by a paper saved to your library',
      savedToLibraryDropdownLabel: 'This paper is saved in your library',
      citationsHeader: 'Citations',
      savedToLibraryHeader: 'Saved To Library',
      citedByLibraryHeader: 'Cited By Library',
      citedByLibraryDescription:
        'Citations of papers you have in your library will be marked in pink.',
      panelTitle: 'Citation Cards',
      panelDescription: 'Easily view cited papers and how they relate to you',
      feedbackDescription: 'If you have feedback on this experience,',
      feedbackContactUs: 'contact us',
      citedByLibraryText: 'Cited By Your Library',
      citedByLibraryExplanationDescription: {
        singular: 'This paper is cited by a paper saved in your library',
        plural: 'This paper is cited by $1 papers saved in your library',
      },
      citationsToggleDescription:
        'Enable pop up Citation Cards with details on the cited paper for inline citations identified in this paper.',
      loggedInPrompt: 'Log in to view references related to you',
      savedToLibraryText: 'This paper is saved in your library.',
      savedToLibraryDescription: 'Papers you have saved in your library will be marked in orange.',
      savedToLibraryFolderText: 'This paper is saved in your folder ',
      moreFoldersText: {
        singular: ' and $1 other folder.',
        plural: ' and $1 other folders.',
      },
      inFolderText: 'in ',
      examples: 'Example:',
      example1: '(Allen et al., 2015)',
      example2: '[16]',
      paperCount: {
        singular: '($1 paper)', // $1 = number of papers
        plural: '($1 papers)', // $1 = number of papers
      },
    },
    settingsPanel: {
      examples: 'Example:',
      feedbackDescription: 'Feedback?',
      feedbackContactUs: 'Contact Us',
      feedbackContactUsAriaLabel: 'If you have feedback on this experience, contact us',
    },
    skimming: {
      settings: 'Settings',
      back: 'Back',
      panelTitle: 'Skimming Highlights',
      panelDescription: 'AI-generated highlighting to support skimming',
      panelAriaLabel:
        'AI-generated highlights extracted to support skimming. Highlights will be announced verbally in the text and text snippets are gathered in the panel below.',
      faqAriaLabel: 'FAQ: What are skimming highlights',
      toggleType: {
        result: {
          ariaLabelDesc: 'Paper results are highlighted in pink.',
          ariaLabelExample: 'Example, Our results show…',
          label: 'Results',
          description: 'Paper results are highlighted in pink.',
          example: 'Our results show…',
        },
        objective: {
          ariaLabelDesc: 'Paper objectives are highlighted in blue.',
          ariaLabelExample: 'Example, In this work, we propose…',
          label: 'Goals',
          description: 'Paper objectives are highlighted in blue.',
          example: 'In this work, we propose…',
        },
        method: {
          ariaLabelDesc: 'Methods used are highlighted in orange.',
          ariaLabelExample: 'Example, The methods used…',
          label: 'Methods',
          description: 'Methods used are highlighted in orange.',
          example: 'The methods used…',
        },
      },
      opacity: {
        title: 'Highlight Opacity',
        name: 'opacity',
        light: {
          label: 'Light',
          ariaLabel: 'Light Highlight Color Opacity',
        },
        medium: {
          label: 'Medium',
          ariaLabel: 'Medium Highlight Color Opacity',
        },
        dark: {
          label: 'Dark',
          ariaLabel: 'Dark Highlight Color Opacity',
        },
        none: {
          label: 'None (Margin Labels Only)',
          ariaLabel: 'None (Margin Labels Only)',
        },
      },
      examples: 'Example:',
      highlightCount: {
        singular: '($1 highlight)',
        plural: '($1 highlights)',
      },
      exampleTextCase: 'Aa',
      numberOfHighlightsSection: {
        title: 'Number of Highlights',
        description: {
          many: 'Increasing highlights may impact the accuracy of the tool.',
          few: 'Decreasing highlights may leave some details out.',
        },
        options: {
          many: 'Many Highlights',
          few: 'Few Highlights',
        },
      },
      marginLabelsToggleSection: {
        title: 'Show Margin Flags',
        description: 'Label highlight types in the margin.',
      },
      enableSkimmingToggle: {
        title: 'Enable Skimming',
        description: 'Show automatically-generated highlights',
      },
      emptyState: {
        title: 'No Highlights Available',
        description: 'Try updating your settings to add more highlighted text',
      },
      highlightVoiceOver: 'Begin $1 highlighting $2 end $1 highlighting',
    },
    termUnderstanding: {
      panelTitle: 'Term Lookup',
      panelDescription: 'Define terms in this paper using AI.',
      aiGeneratedLabel: 'AI-Generated',
      usingThisPaper: 'Using This Paper',
      aiGeneratedExplanation:
        'Our system tries to constrain to information from mentions found in this paper, but you may find inconsistencies.',
      learnMore: 'Learn more',
      learnMoreContinued: ' about how we generate these definitions',
      acronymsToggleSection: {
        title: 'Acronyms',
        description:
          'Acronyms that are defined in this paper are underlined with pointers to the defining page',
      },
      errorLoadingDefinition:
        'An error occurred while loading the definition. Please try again later.',
      termsToggleSection: {
        title: 'Generated Definitions',
        description: 'Get definitions for terms in this paper using AI',
      },
      boundingBoxLabel: '$1, Definition available',
      errors: {
        UNKNOWN_ERROR: {
          title: 'Something Went Wrong',
          desc: 'An error occurred while loading the definition. Please try again later.',
        },
        QUES2IONABLE_RATE_LIMIT: {
          title: 'Rate Limit Exceeded',
          desc: 'We were unable to load the definition. Please try again later.',
        },
      },
      noAcronyms: 'Acronym definitions are not available on this paper',
      noTerms: 'AI-generated definitions are not available on this paper',
    },
    saveToLibrary: 'Save To Library',
    saveToLibraryMobile: 'Library',
    tableOfContents: 'Table Of Contents',
    thumbnails: 'Thumbnails',
    bannerPdfLink: 'PDF Provided By',
    navigateButton: 'Navigate Paper',
    navigateButtonMobile: 'Navigate',
    shareButton: 'Share',
    aboutButton: 'About',
    feedback: 'Give Feedback',
    feedbackMobile: 'Feedback',
    pageLoading: 'Page Loading',
    skimmingFlagLabels: {
      objective: 'Goal',
      method: 'Method',
      result: 'Result',
    },
    skimmingSnippetRange: `Page $1`,
    ariaLabel: {
      aboutButton: 'About Semantic Reader',
      alertCreateButton: 'Subscribe to email alerts for citations of this paper',
      citationCardTrigger: `Citation Card: $1`,
      citationButton: 'Citation Cards',
      citationPanelName: 'Citation Cards',
      citedByLibraryDescription:
        'Papers cited by your library will be verbally noted with the in-line citation.',
      feedbackContactUs: 'If you have feedback on this experience, contact us',
      citeseeCitedByLabel: 'For you, citation Cited By Library',
      citeseeSavedByLabel: 'For you, citation Saved By Library',
      savedToLibraryDescription:
        'Papers you have saved in your library will be verbally noted with the in-line citation.',
      savedToLibraryExample:
        'Example, For You, Citation (Allen et al., 2015), saved to your library',
      citedByLibraryExample:
        'Example, For You, Citation (Allen et al., 2015), cited by your library',
      citationCardLoading: 'Citation Card Loading',
      termCardLoading: 'Term Definition Loading',
      downloadButton: 'Download PDF',
      tableOfContents: 'Toggle Table Of Contents',
      feedbackButton: 'Give Feedback',
      navigateButton: 'Navigate Paper',
      shareButton: 'Copy a shareable link to your clipboard',
      shareButtonCopied: 'Copied',
      skimmingPanelName: 'Skimming',
      skimmingButton: 'Highlight key points of this paper',
      skimmingEnabled: 'Showing Skimming Highlights',
      skimmingDisabled: 'Hiding Skimming Highlights',
      skimmingButtonDisabled: 'Skimming Assist Unavailable',
      termLookupButton: 'Term Lookup',
      termLookupButtonDisabled: 'Term Lookup Unavailable',
      thumbnails: 'Toggle Thumbnails',
      printButton: 'Print PDF',
      zoomDropdown: 'Zoom Options',
      zoomInButton: 'Zoom In',
      zoomOutButton: 'Zoom Out',
      noteTakingButton: 'Annotations',
      noteTakingPanelName: 'annotation',
      widgetPanelName: 'widget',
      widgetPanelCloseButton: 'Close $1 panel',
      expandWidgetPanel: 'Adjust width of right-hand panel',
    },
    zoomFitWidth: 'Fit Width',
    zoomFitHeight: 'Fit Height',
    downloadButton: 'Download',
    printButton: 'Print',
    tooltips: {
      backButton: 'Back to Paper Overview',
      citations: 'Citation Cards',
      downloadButton: 'Download PDF',
      printButton: 'Print PDF',
      noteTaking: 'Annotations',
      shareButton: 'Copy a shareable link to your clipboard',
      shareButtonCopied: 'Copied',
      skimmingUnavailable: 'Unavailable',
      termUnderstanding: 'Term Lookup',
      termsUnavailable: 'Term Lookup Unavailable',
      unavailableTableOfContents: 'Table of Contents unavailable',
    },
    createAlertButton: 'Create Alert',
    createAlertButtonMobile: 'Alert',
    disableAlertButton: 'Disable Alert',
    disableAlertButtonMobile: 'Alert',
    activateAlertButton: 'Activate Alert',
    activateAlertButtonMobile: 'Alert',
    referenceTextPopoverTitle: 'No Paper Link Available',
    referenceTextPopoverDescription: 'Here is what we have:',
    errorPopoverTitle: 'Something Went Wrong',
    errorPopoverDescription: 'Citation Information Unavailable',
    somethingNotRight: 'Something not right?',
    contactUs: 'Contact Us',
    noteTaking: {
      poweredBy: 'Powered by',
      hypothesis: 'Hypothes.is',
      annotations: 'Annotations',
      settings: 'Settings',
      back: 'Back',
      viewAnnotationsToggleSection: {
        title: 'View Annotation Highlights',
        description: 'Controls visibility of all highlights in the text',
      },
      enableAnnotationsToggleSection: {
        title: 'Enable Annotations',
        description:
          'When enabled, use this tool to add highlights, comments, and view past annotations',
      },
      deprecationTitle: 'Important:',
      deprecationWarning:
        'Hypothesis integration within Semantic Reader will be discontinued in January 2024. As an alternative, you can continue to use it by installing <a href="https://web.hypothes.is/start" target="_blank">Hypothesis’ browser extension</a>.',
    },
    widgetsLabel: {
      citations: 'Citation Cards',
      noteTaking: 'Annotations',
      skimming: 'Skimming Assist',
      termUnderstanding: 'Term Lookup',
    },
  },
  overridePanel: {
    closeButtonLabel: 'Close',
  },
  venuePage: {
    loading: 'Loading results',
    pageTitleWithVenueName: '$1 | Semantic Scholar', // $1 = Search query
    pageTitleWithoutVenueName: 'Venue Page | Semantic Scholar',
    pageTitleWhileLoading: 'Loading ...',
  },
};

export default langMap;
