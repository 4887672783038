/* eslint-disable react/prop-types */

import { getPluralizedString, getString, Getter } from '@/content/i18n';

import escape from 'escape-html';
import React from 'react';

import type { Nullable } from '@/utils/types';

/**
 * EXAMPLE USAGES:
 *
 * - HTML in content file:
 *   'Click "Sign in" to accept the <a href="https://allenai.org/terms.html" target="_blank">Terms of Service</a>'
 *   <ContentWithHTML content={_ => _.login.signUp.termsAndPrivacy} />
 *
 * - HTML in content file with replacements:
 *   'Successful changes are applied directly to your <a href="$1">author page</a>.' // $1 = path to user's author page
 *   <ContentWithHTML content={_ => _.account.page.corrections.pageDescription} args={[authorPageHref]} />
 *
 * - HTML with pluralized string:
 *   'Removing <strong>$1 Papers</strong> From Your Author Page, // $1 = number of papers
 *   <ContentWithHTML plural={_ => _.authorRemovePapers.stagedRemoveCorrections} number={numPapers} />
 *
 * - HTML with pluralized string and replacements:
 *   "Removing <strong>$1 Papers</strong> From $2's Author Page" // $1 = number of papers,  $2 = Author's name
 *   <ContentWithHTML plural={_ => _.moderatorCorrections.removePapers.status} number={numPapers} args={[authorName]} />
 */

type PropsWithContentDEPRECATED = {
  content__DEPRECATED: string;
  content?: never;
  plural?: never;
};

type PropsWithContent = {
  content__DEPRECATED?: never;
  content: Getter<string>;
  plural?: never;
};

type PropsWithPlural = {
  content__DEPRECATED?: never;
  content?: never;
  plural: Nullable<Getter<{ singular: string; plural: string }>>;
};

type Props = {
  tag?: Nullable<string>;
  args?: Nullable<string[]>;
  number?: Nullable<number>;
} & (PropsWithContentDEPRECATED | PropsWithContent | PropsWithPlural);

export default function ContentWithHTML(props: Props) {
  const {
    tag,
    content__DEPRECATED,
    content: stringGetter,
    plural: pluralStringGetter,
    args,
    number,
    ...propsForElem
  } = props;

  const tmplHTML = (() => {
    if (stringGetter) {
      return getString(stringGetter);
    }
    if (pluralStringGetter && typeof number === 'number') {
      return getPluralizedString(pluralStringGetter, number);
    }
    return content__DEPRECATED || '';
  })();

  const safeHTML = (args || []).reduce(
    (str, arg, i) => str.replace(`$${i + 1}`, escape(arg)),
    tmplHTML
  );
  const elemProps = {
    ...propsForElem,
    dangerouslySetInnerHTML: { __html: safeHTML },
  };
  return React.createElement(tag || ContentWithHTML.defaultProps.tag, elemProps, null);
}

ContentWithHTML.defaultProps = {
  tag: 'span',
};
